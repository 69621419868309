import { v4 as uuid } from 'uuid';

import { DeliveryTierState } from '../reducers/deliverytier/deliveryTierConstants';
import { DeliveryTierResponse } from '../types/deliveryTier';

const hydrateDeliveryTier = (data: DeliveryTierResponse): DeliveryTierState => ({
  id: data.id,
  name: data.name,
  dateSelection: data.dateSelection,
  displayNames: data.displayNames?.map(displayName => ({ ...displayName, id: uuid() })) ?? [],
  tags: data.tags ?? [],
  hasUnsavedWork: false,
  isNewTier: false,
  isDeleted: false,
  isValid: true,
});

export default hydrateDeliveryTier;
