import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

const evaluateTemplate = ({
  template,
  locale,
  earliestDate,
  earliestOfAllDates,
  latestDate,
}: {
  template: string;
  locale?: string;
  earliestDate: string;
  earliestOfAllDates: string;
  latestDate: string;
}) => {
  const setLocale = locale || 'en-US';
  // An example of a valid template piece: {{formatDate latestDate "ccc, LLL d"}}
  const templatePieces = uniq(template.match(/(?<=\{{)(.*?)(?=\}})/g));
  let resolvedTemplate: string = template;

  map(templatePieces, (piece: string) => {
    if (
      (piece.includes('earliestDate') || piece.includes('latestDate') || piece.includes('earliestOfAllDates')) &&
      // Make sure someone didn't add extra brackets inside
      !piece.includes('{') &&
      !piece.includes('}')
    ) {
      let dateToReplace = piece.split(' ')[1];
      const format = piece.split(' ').splice(2).join(' ').replaceAll('"', '').replaceAll("'", '');
      const toFormat = !isEmpty(format) ? format : 'DDDD';

      let date;
      const doingAddition = dateToReplace.includes('+');
      const doingSubtraction = dateToReplace.includes('-');

      if (doingAddition || doingSubtraction) {
        const operator = doingAddition ? '+' : '-';
        const bits = dateToReplace.split(operator);
        let workingDaysToAdd;
        [dateToReplace, workingDaysToAdd] = bits;

        const startDate =
          dateToReplace === 'earliestOfAllDates'
            ? earliestOfAllDates
            : dateToReplace === 'earliestDate'
            ? earliestDate
            : latestDate;
        let totalDate = startDate;
        if (doingSubtraction) {
          totalDate = DateTime.fromISO(startDate).minus({ days: workingDaysToAdd }).toISO();
        } else {
          totalDate = DateTime.fromISO(startDate).plus({ days: workingDaysToAdd }).toISO();
        }

        date = totalDate;
      } else {
        date =
          dateToReplace === 'earliestOfAllDates'
            ? earliestOfAllDates
            : dateToReplace === 'earliestDate'
            ? earliestDate
            : latestDate;
      }

      const formattedDate = DateTime.fromISO(date).setLocale(setLocale).toFormat(toFormat);
      resolvedTemplate = resolvedTemplate.replaceAll(`{{${piece}}}`, formattedDate);
    } else {
      throw new Error('Bad template!');
    }
  });

  // Check for any keywords or symbols that may have slipped through
  map(['{', '}', 'formatDate', 'earliestDate', 'earliestOfAllDates', 'latestDate'], s => {
    if (resolvedTemplate.includes(s)) {
      throw new Error('Bad template!');
    }
  });

  return resolvedTemplate;
};

export default evaluateTemplate;
