import { DeliveryTierVersion } from '../../types/deliveryTier';

export type DeliveryTierVersionState = {
  versions: DeliveryTierVersion[];
};

export const initialDeliveryTierVersions = (): DeliveryTierVersionState => {
  return {
    versions: [],
  };
};
