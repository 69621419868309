import orderBy from 'lodash/orderBy';

import { getAccountsWithPermission } from './coam';
import { fetchWithAuth } from './fetchWithAuth';

const accountsEndpointUrl = process.env.REACT_APP_ACCOUNTS_URL as string;

type AccountCollection = {
  _embedded: {
    item: Account[];
  };
};

export type Account = {
  accountId: string;
  status: string;
  name: string;
  essentialGroupId: number;
  _links: {
    self: {
      href: string;
    };
  };
};

export type AccountInfo = {
  name: string;
  id: string;
  url: string;
};

export const getAccounts = async (filter: boolean): Promise<AccountInfo[]> => {
  try {
    const response: AccountCollection = await fetchWithAuth({
      endpointUrl: accountsEndpointUrl,
      route: 'api/v1/accounts?status=Active',
    });
    const accounts = orderBy(response._embedded.item, ['name'], ['asc']);
    const accountInfos = accounts.map((account: Account) => ({
      name: account.name,
      id: account.accountId,
      url: account._links.self.href,
    }));

    return filter ? await getAccountsWithPermission(accountInfos) : accountInfos;
  } catch (err) {
    throw new Error('An error has occurred when retrieving accounts');
  }
};

export const getAccount = (url: string) => fetchWithAuth({ endpointUrl: url });

export const getAccountGroupId = async (accountId: string): Promise<number> => {
  const account: Account = await fetchWithAuth({
    endpointUrl: accountsEndpointUrl,
    route: `api/v1/accounts/${accountId}`,
  });
  return account.essentialGroupId;
};
