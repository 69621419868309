import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';

import { Button, Tooltip } from '@cimpress/react-components';

const messageWrapper = css`
  text-align: center;
`;

const PermissionMessage = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={messageWrapper} {...props}>
      <p>
        You don't have permission to create a new delivery option. Please reach out to{' '}
        <a href="https://cimpress.slack.com/archives/C7CNT9E2Z" target="_blank" rel="noreferrer">
          #mcp-platform-support
        </a>{' '}
        and request the Product Manager role for your account.
      </p>
    </div>
  );
};

export const CreateButton = ({ hasPermission }: { hasPermission: boolean }) => {
  const history = useHistory();

  return (
    <>
      {!hasPermission ? (
        <Tooltip direction="top" tooltipInnerStyle={{ maxWidth: '250px' }} contents={<PermissionMessage />}>
          <Button disabled={true} variant="primary" size="lg" onClick={() => history.push('/create')}>
            Create New Delivery Group
          </Button>
        </Tooltip>
      ) : (
        <Button variant="primary" size="lg" onClick={() => history.push('/create')}>
          Create New Delivery Group
        </Button>
      )}
    </>
  );
};
