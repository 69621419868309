import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { LoudspeakerIcon, LoudspeakerEnvironment } from '@cimpress-technology/react-loudspeaker';
import Header from '@cimpress-technology/react-platform-header';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import Snackbar from '@cimpress/react-components/lib/Snackbar';

import auth from '../auth';
import messages from './messages';
import { SnackBarContext } from './snackbarContext';

const loudspeakerIcon = (
  <LoudspeakerIcon
    accessToken={auth.getAccessToken()}
    channelIds={[process.env.REACT_APP_LOUDSPEAKER_CHANNEL_ID || 'REACT_APP_LOUDSPEAKER_CHANNEL_ID']}
    title="What's new for the EDO Manager?"
    environment={LoudspeakerEnvironment.Production}
  />
);

const Shell = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();

  const queryCache = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarDetails, setSnackbarDetails] = useState<any>({});

  const snackbarSetters = useMemo(
    () => ({ setShowSnackbar, setSnackbarDetails }),
    [setShowSnackbar, setSnackbarDetails],
  );

  const snackbar = (
    <Snackbar
      status={snackbarDetails.type}
      delay={3000}
      show={showSnackbar}
      onHideSnackbar={() => setShowSnackbar(false)}
    >
      {snackbarDetails.details}
    </Snackbar>
  );

  return (
    <CssLoader>
      <Header
        accessToken={auth.getAccessToken()}
        appTitle={<Link to="/">{formatMessage(messages.edoManager)}</Link>}
        appLinks={[]}
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout('/')}
        isLoggedIn={auth.isLoggedIn()}
        notifications={loudspeakerIcon}
      />
      <QueryClientProvider client={queryCache}>
        <SnackBarContext.Provider value={snackbarSetters}>
          {snackbar}
          <div style={{ minHeight: 'calc(100vh - 225px)' }}>{children}</div>
        </SnackBarContext.Provider>
      </QueryClientProvider>
    </CssLoader>
  );
};

export default Shell;
