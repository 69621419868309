import { UseQueryResult } from '@tanstack/react-query';
import { detailedDiff } from 'deep-object-diff';
import produce from 'immer';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { Version } from './deliverygroup/deliveryGroupVersionsActions';
import { DiffType, removeEmptyObjects, wasDeleted } from './versionChangeMappings';

export type DiffKeys = DiffType;
export type Diff = {
  versionType: VersionType;
  nameOfItem: string | undefined;
  isFirstOfType: boolean;
  id: string;
  changes: Record<DiffKeys, Record<string, any>>;
};

export type VersionGroup = {
  date: number; // in milliseconds
  user: string;
  changesMade: VersionType[];
  versions: Version[];
  diffs: Diff[];
};
export type VersionType = 'group' | 'option' | 'tier';

export const getDataFromResponses = <T extends { etag: string }>(responses: UseQueryResult<T, unknown>[]) => {
  if (responses.length === 0) {
    return;
  }

  const errors: UseQueryResult<T, unknown>[] = [];

  const responseData = responses.reduce((acc, response) => {
    if (response.data) {
      acc.push(response.data);
    } else if (response.status === 'error') {
      errors.push(response);
    }
    return acc;
  }, [] as T[]);

  return {
    responses: responseData,
    errors,
  };
};

export const getDateFromVersion = (version: Version) => {
  return version.modifiedAt ? version.modifiedAt : version.createdAt;
};

export const getUserFromVersion = (version: Version) => {
  return version.modifiedBy ? version.modifiedBy : version.createdBy;
};

// Converts the date, hours and minutes from an ISO value to milliseconds
const getDateTimeInMilliseconds = (version: Version): number => {
  return DateTime.fromISO(getDateFromVersion(version)).startOf('second').toMillis();
};

export const sortInDescOrderByDate = (versions: VersionGroup[]) => {
  return versions.sort((a, b) => {
    const dateA = DateTime.fromMillis(a.date);
    const dateB = DateTime.fromMillis(b.date);

    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
  });
};

// Adjust diff output to make it easier to explain changes in
// the UI
const cleanDiffs = produce((diff: Record<DiffKeys, any>) => {
  Object.entries(diff).map(([key, value]) => {
    return Object.entries(value).map(([subkey, subvalue]) => {
      /**
       * The diff library returns the same key in
       * the deleted and updated objects when something is deleted
       * from the configuration. So, if you delete displayNames,
       * for example, you'd see this:
       *
       * {
       *    added: {},
       *    deleted: { displayNames: { 0: undefined } },
       *    updated: { displayNames: {} }
       * }
       *
       * So, we check for both conditions and remove the empty value
       * in the updated object to accurately reflect in the UI that it was
       * deleted.
       */
      if (wasDeleted(diff, subkey)) {
        removeEmptyObjects(diff.deleted[subkey]);
        delete diff.updated[subkey];
      }

      return [subkey, subvalue];
    });
  });

  return diff;
});

export const getDiffChanges = (
  versionGroups: VersionGroup[],
  versionsWithDiffs: VersionGroup[] = [],
): VersionGroup[] => {
  if (versionGroups.length === 0) {
    return versionsWithDiffs;
  }

  versionGroups.forEach(versionGroup => {
    const versionQueue = versionGroup.versions;

    versionQueue.forEach(version => {
      const { versionType, version: currentVersion, id, name } = version;
      const currentVersionNumber = Number(currentVersion);

      // The initial version won't have an actual diff, but it should be considered
      // an "added" change
      if (currentVersionNumber === 1) {
        versionGroup.diffs.push({
          versionType,
          nameOfItem: name,
          isFirstOfType: true,
          id: `${id}-${name}-0-${currentVersion}-${versionType}`,
          changes: cleanDiffs({
            added: version,
            deleted: {},
            updated: {},
          }),
        });

        return;
      }

      if (currentVersionNumber > 1) {
        const previousVersion = versionGroups.reduce((acc, version) => {
          const matchingVersion = version.versions.find(
            v => Number(v.version) === currentVersionNumber - 1 && v.versionType === versionType && v.id === id,
          );
          if (matchingVersion) {
            acc.push(matchingVersion);
          }
          return acc;
        }, [] as Version[])[0];

        if (!previousVersion) {
          versionGroup.diffs.push({
            versionType,
            nameOfItem: name,
            isFirstOfType: false,
            id: `${id}-${name}-${currentVersion}-${versionType}`,
            changes: cleanDiffs({
              added: version,
              deleted: {},
              updated: {},
            }),
          });

          return;
        }

        const diff = detailedDiff(previousVersion, version);

        versionGroup.diffs.push({
          versionType,
          nameOfItem: name,
          isFirstOfType: false,
          id: `${id}-${name}-${previousVersion.version}-${currentVersion}-${versionType}`,
          changes: cleanDiffs(diff),
        });
      }
    });
  });

  return versionGroups;
};

// Group versions by datetime and user so that we can show one row in the UI table
// for all version types (group, option, tier) that a user made at the same time
export const groupVersions = (versions: Version[]): VersionGroup[] => {
  const dateMap: { [key: string]: { user: string; changesMade: Set<VersionType>; versions: Version[] } } = {};

  versions.forEach(version => {
    const date = getDateTimeInMilliseconds(version).toString();
    const user = getUserFromVersion(version);

    const existingValue = dateMap[date];
    if (!existingValue) {
      dateMap[date] = {
        user,
        changesMade: new Set<VersionType>().add(version.versionType),
        versions: [version],
      };
      return;
    }

    dateMap[date].changesMade.add(version.versionType);
    dateMap[date].versions.push(version);
  });

  return Object.entries(dateMap).map(([key, value]) => {
    const changesMadeArr = Array.from(value.changesMade);

    return {
      date: Number(key),
      user: value.user,
      id: Number(key),
      changesMade: changesMadeArr,
      versions: value.versions,
      diffs: [],
    };
  });
};

type VersionResponseGen<T> = {
  _embedded?: { item: T[] };
};

export const getVersionsFromResponses = <T>(
  response: VersionResponseGen<T>[],
  currentVersionState?: { versions: T[] },
) => {
  const versions: T[] = response
    .reduce((acc: T[][], versionResponse: VersionResponseGen<T>) => {
      const { _embedded } = versionResponse;
      if (!_embedded) {
        return acc;
      }

      const { item: versionsFromResponse } = _embedded;
      if (!versionsFromResponse || versionsFromResponse.length === 0) {
        return acc;
      }

      acc.push(versionsFromResponse);
      return acc;
    }, [])
    .flat();

  if (currentVersionState && isEqual(currentVersionState.versions, versions)) {
    return;
  }

  return { versions };
};
