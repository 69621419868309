import React from 'react';
import countryList from 'react-select-country-list';

import { Select } from '@cimpress/react-components';

import { SelectField } from '../../../types/shared';

const CountrySelect = ({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (selectedDispatchCountry?: SelectField | null) => void;
  className: any;
}) => {
  const options: SelectField[] = countryList()
    .getLabels()
    .map((countryName: string) => {
      const countryCode = countryList().getValue(countryName);
      return { value: countryCode, label: countryName };
    });
  const selectedValue = options.find(option => option.value === value);
  return (
    <Select
      isClearable
      className={className}
      label="Dispatch Country"
      options={options}
      value={selectedValue}
      onChange={onChange}
    />
  );
};

export default CountrySelect;
