import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import React, { ReactNode, useMemo, useState } from 'react';

import IconFilterAlt from '@cimpress-technology/react-streamline-icons/lib/IconFilterAlt';
import { Drawer, Accordion, Checkbox, Button } from '@cimpress/react-components';

import { Filters } from './HomePage';

type Filter = {
  filterName: string;
  filteredValues: string[];
  setFilteredValues: React.Dispatch<React.SetStateAction<string[]>>;
  filterOptions: any[];
};

export const accordionFilter = (
  title: string,
  appliedFilters: string[],
  currentFilters: string[],
  filterOptions: {
    payload: string;
    label: string;
  }[],
  selectFunction: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const filterTitle = `${title} ${currentFilters.length > 0 ? `(${currentFilters.length})` : ''}`;
  const sortedFilterOptions = sortBy(filterOptions, [
    filter => !appliedFilters.includes(filter.payload),
    filter => filter.label,
  ]);

  return (
    <Accordion title={filterTitle} bodyStyle={{ padding: 0 }}>
      {sortedFilterOptions.map(({ label, payload }, idx) => {
        const checked = currentFilters.includes(payload);
        const onChange = () => {
          const updatedFilters = checked ? without(currentFilters, payload) : concat(currentFilters, payload);
          selectFunction(updatedFilters);
        };
        return (
          <div key={idx}>
            <hr style={{ margin: 0 }} />
            <Checkbox
              label={label}
              checked={checked}
              payload={payload}
              onChange={onChange}
              style={{ padding: '6px 16px' }}
            />
          </div>
        );
      })}
    </Accordion>
  );
};

const TableFilter = ({
  filterProps,
  setFilters,
  children,
}: {
  filterProps: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  children: ReactNode;
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const onClearAll = () => {
    filterProps.forEach(filter => filter.setFilteredValues([]));
  };

  const onApplyFilters = () => {
    const customFilters = filterProps.reduce((acc, value) => {
      acc[value.filterName] = value.filterOptions;
      return acc;
    }, {} as { [key: string]: any });

    setFilters((filters: Filters) => ({ ...filters, ...customFilters }));
    setShowDrawer(false);
  };

  const numberOfFilters = useMemo(
    () =>
      filterProps.reduce((acc, value) => {
        return acc + value.filteredValues.length;
      }, 0),
    [filterProps],
  );

  const footer = (
    <div>
      <Button variant="default" onClick={onClearAll}>
        Clear All
      </Button>
      <Button variant="primary" onClick={onApplyFilters}>
        Apply
      </Button>
    </div>
  );

  return (
    <div>
      <Button variant="link" size="lg" onClick={() => setShowDrawer(true)}>
        <IconFilterAlt weight="fill" />
        {`Advanced Filters ${numberOfFilters > 0 ? `(${numberOfFilters})` : ''}`}
      </Button>
      <Drawer
        show={showDrawer}
        onRequestHide={onApplyFilters}
        header={<h1>Advanced Filters</h1>}
        closeOnClickOutside
        footer={footer}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default TableFilter;
