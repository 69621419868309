import { useQuery } from '@tanstack/react-query';

import { getDeliveryOptions } from '../../services/deliveryOptions';
import { HydratedDeliveryOptionResponse } from '../../types/deliveryOption';

const useDeliveryOptions = ({
  deliveryGroupId,
  onError,
  onSuccess,
  enabled = true,
}: {
  deliveryGroupId: string;
  onError?: (error: Error) => void;
  onSuccess?: (data: HydratedDeliveryOptionResponse[]) => void;
  enabled?: boolean;
}) => {
  return useQuery(['delivery-options', { id: deliveryGroupId }], () => getDeliveryOptions({ deliveryGroupId }), {
    enabled,
    onError,
    onSuccess,
  });
};

export default useDeliveryOptions;
