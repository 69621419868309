import { fetchWithAuth } from './fetchWithAuth';

const capabilitiesUrl = process.env.REACT_APP_CARRIERS_URL as string;

type CarrierService = {
  key: string;
  name: string;
  carrierName: string;
  carrierKeys: string[];
};

type Capability = {
  key: string;
  name: string;
  description?: string;
};

export const getCarrierServices = async (): Promise<CarrierService[]> => {
  const carrierServices: CarrierService[] = [];
  let next = `${capabilitiesUrl}/api/v1/carrierServices?size=200`;
  do {
    const response: any = await fetchWithAuth({ endpointUrl: next });
    carrierServices.push(...response.carrierServices);
    next = response._links.next?.href;
  } while (next);

  return carrierServices;
};

export const getCarrierServiceCapabilities = async (): Promise<Capability[]> => {
  const response: any = await fetchWithAuth({ endpointUrl: capabilitiesUrl, route: 'api/v1/capabilities' });
  const capabilities = response.capabilities;
  capabilities.push(
    ...[
      {
        key: 'recs:cap:fast',
        name: 'Pixart Fast',
      },
      {
        key: 'recs:cap:normal',
        name: 'Pixart Normal',
      },
      {
        key: 'recs:cap:slow',
        name: 'Pixart Slow',
      },
    ],
  );
  return capabilities;
};
