import { css } from '@emotion/css';
import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { Button, TextField, Accordion, InlineEdit, colors } from '@cimpress/react-components';

import { isProductConsolidationStateValid } from '../../../helpers/validateStates';
import {
  addConsolidation,
  moveConsolidation,
  removeConsolidation,
  setConsolidationField,
} from '../../../reducers/deliverygroup/deliveryGroupActions';
import { ProductConsolidationState } from '../../../reducers/deliverygroup/deliveryGroupConstants';
import { draggableItemStyle } from '../../../styles/draggableItemStyle';

const accordionBodyStyle = css`
  &.accordion .accordion-body {
    padding: 0 25px 0 45px;
    width: 60%;
  }
`;

const ConsolidationsEditor = ({
  consolidations,
  dispatch,
}: {
  consolidations: ProductConsolidationState[];
  dispatch: React.Dispatch<unknown>;
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      moveConsolidation({
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      }),
    );
  };

  const isDragDisabled = consolidations.length === 1;
  const draggableConsolidations = consolidations.map((consolidation, index) => (
    <Draggable
      key={consolidation.consolidationId}
      draggableId={consolidation.consolidationId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={draggableItemStyle(snapshot.isDragging)}
        >
          <Accordion
            title={
              <InlineEdit
                placeholder={'Consolidation Name'}
                value={consolidation.name}
                onKeyDownCapture={e => e.key === ' ' && e.stopPropagation()}
                onClickCapture={e => e.stopPropagation()}
                onChange={e =>
                  dispatch(
                    setConsolidationField({
                      consolidationId: consolidation.consolidationId,
                      field: 'name',
                      value: e.target.value,
                    }),
                  )
                }
              />
            }
            className={accordionBodyStyle}
            actions={
              <div style={{ display: 'flex' }}>
                {!isProductConsolidationStateValid(consolidation) && (
                  <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ marginTop: 12 }} />
                )}
                <Button
                  variant="link"
                  aria-label="Delete"
                  onClick={() => dispatch(removeConsolidation({ consolidationId: consolidation.consolidationId }))}
                  icon={<IconBin size="lg" />}
                />
              </div>
            }
          >
            <TextField
              label="Comma Separated Skus"
              value={consolidation.skus?.join(',') ?? ''}
              onChange={e =>
                dispatch(
                  setConsolidationField({
                    consolidationId: consolidation.consolidationId,
                    field: 'skus',
                    value: e.target.value?.split(',').map(sku => sku.trim()),
                  }),
                )
              }
            />
          </Accordion>
        </div>
      )}
    </Draggable>
  ));

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {draggableConsolidations}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button onClick={() => dispatch(addConsolidation())}>Add Consolidation</Button>
    </div>
  );
};

export default ConsolidationsEditor;
