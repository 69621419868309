import React from 'react';

import { TextField } from '@cimpress/react-components';

const NumberField = ({
  label,
  value,
  onChange,
  isInvalid = false,
}: {
  label: string;
  value?: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
}) => (
  <TextField
    style={{ width: '100px' }}
    label={label}
    type="number"
    min="0"
    value={value}
    onChange={onChange}
    status={isInvalid ? 'error' : ''}
  />
);

export default NumberField;
