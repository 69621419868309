import { DisplayNameState } from '../reducers/deliveryoption/deliveryOptionConstants';

export const getSelectedLocales = (displayNames: DisplayNameState[]) => {
  return displayNames.reduce((acc, val) => {
    if (val.locale !== undefined) {
      acc.push(val.locale);
    }

    return acc;
  }, [] as string[]);
};
