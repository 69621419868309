import { useQuery } from '@tanstack/react-query';

import { getCarrierServices, getCarrierServiceCapabilities } from '../services/carriers';

export const useCarrierServices = () => {
  return useQuery(['carrierServices'], getCarrierServices);
};

export const useCarrierServiceCapabilities = () => {
  return useQuery(['carrierServiceCapabilities'], getCarrierServiceCapabilities);
};
