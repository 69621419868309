import produce from 'immer';

import * as actions from './deliveryOptionActions';
import {
  initialDeliveryOption,
  initialDestination,
  initialPostalCodeRange,
  initialDisplayName,
} from './deliveryOptionConstants';

const getMatchingIndex = (arr: { id: string }[], id: string) => arr.findIndex(element => element.id === id);

const deliveryOptionReducer = produce((draft, action) => {
  switch (action.type) {
    case actions.ADD_DELIVERY_OPTION: {
      const newOptionState = initialDeliveryOption();
      if (action.payload) {
        const { deliveryTierId } = action.payload;
        newOptionState.deliveryTierId = deliveryTierId;
      }
      draft.push(newOptionState);
      break;
    }

    case actions.REMOVE_DELIVERY_OPTION: {
      const { edoId } = action.payload;
      const index = getMatchingIndex(draft, edoId);
      const optionToRemove = draft[index];
      optionToRemove.isNewOption ? draft.splice(index, 1) : (optionToRemove.isDeleted = true);
      break;
    }

    case actions.VALIDATE_DELIVERY_OPTION: {
      const { edoId, isValid } = action.payload;
      const index = getMatchingIndex(draft, edoId);
      draft[index].isValid = isValid;
      break;
    }

    case actions.SET_FIELD: {
      const { edoId, field, value } = action.payload;
      const index = getMatchingIndex(draft, edoId);
      draft[index][field] = value;
      draft[index].hasUnsavedWork = true;
      break;
    }

    case actions.ADD_DESTINATION: {
      const { edoId } = action.payload;
      const index = getMatchingIndex(draft, edoId);
      draft[index].destinations.push(initialDestination());
      draft[index].hasUnsavedWork = true;
      break;
    }

    case actions.REMOVE_DESTINATION: {
      const { edoId, destinationId } = action.payload;
      const edoIndex = getMatchingIndex(draft, edoId);
      const destinationIndex = getMatchingIndex(draft[edoIndex].destinations, destinationId);
      draft[edoIndex].destinations.splice(destinationIndex, 1);
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.SET_DESTINATION_FIELD: {
      const { edoId, destinationId, field, value } = action.payload;
      const edoIndex = getMatchingIndex(draft, edoId);
      const destinationIndex = getMatchingIndex(draft[edoIndex].destinations, destinationId);
      draft[edoIndex].destinations[destinationIndex][field] = value;
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.ADD_POSTAL_CODE_RANGE: {
      const { edoId, destinationId } = action.payload;
      const edoIndex = getMatchingIndex(draft, edoId);
      const destinationIndex = getMatchingIndex(draft[edoIndex].destinations, destinationId);
      draft[edoIndex].destinations[destinationIndex].postalCodeRanges.push(initialPostalCodeRange());
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.REMOVE_POSTAL_CODE_RANGE: {
      const { edoId, destinationId, postalCodeRangeId } = action.payload;
      const edoIndex = getMatchingIndex(draft, edoId);
      const destinationIndex = getMatchingIndex(draft[edoIndex].destinations, destinationId);
      const postalCodeRangeIndex = getMatchingIndex(
        draft[edoIndex].destinations[destinationIndex].postalCodeRanges,
        postalCodeRangeId,
      );

      draft[edoIndex].destinations[destinationIndex].postalCodeRanges.splice(postalCodeRangeIndex, 1);
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.SET_POSTAL_CODE_RANGE_FIELD: {
      const { edoId, destinationId, postalCodeRangeId, field, value } = action.payload;
      const edoIndex = getMatchingIndex(draft, edoId);
      const destinationIndex = getMatchingIndex(draft[edoIndex].destinations, destinationId);
      const postalCodeRangeIndex = getMatchingIndex(
        draft[edoIndex].destinations[destinationIndex].postalCodeRanges,
        postalCodeRangeId,
      );

      draft[edoIndex].destinations[destinationIndex].postalCodeRanges[postalCodeRangeIndex][field] = value;
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.ADD_DISPLAY_NAME: {
      const { resourceId } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      draft[edoIndex].displayNames.push(initialDisplayName());
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.REMOVE_DISPLAY_NAME: {
      const { resourceId, displayNameId } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      const displayNameIndex = getMatchingIndex(draft[edoIndex].displayNames, displayNameId);
      draft[edoIndex].displayNames.splice(displayNameIndex, 1);
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.SET_DISPLAY_NAME_FIELD: {
      const { resourceId, displayNameId, field, value } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      const displayNameIndex = getMatchingIndex(draft[edoIndex].displayNames, displayNameId);
      draft[edoIndex].displayNames[displayNameIndex][field] = value;
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.POPULATE_DELIVERY_OPTIONS: {
      return action.payload;
    }

    default:
      return draft;
  }
});

export default deliveryOptionReducer;
