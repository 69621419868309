import { css } from '@emotion/css';
import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useState } from 'react';

import { Select } from '@cimpress/react-components';

import { setField } from '../../../reducers/deliveryoption/deliveryOptionActions';
import {
  betweenDays,
  betweenOffset,
  betweenOffsetSelection,
  betweenSelection,
  DeliveryOptionState,
} from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../../types/shared';
import NumberField from '../../shared/NumberField';

const textBoxStyles = css`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
  .form-group:first-of-type {
    flex: 1;
    max-width: 280px;
  }
  .form-group:not(:first-of-type) {
    margin-left: 10px;
    flex: 1;
    max-width: 80px;
  }
`;

const DeliveryDays = ({
  deliveryOption,
  dispatch,
  edoId,
}: {
  deliveryOption: DeliveryOptionState;
  dispatch: React.Dispatch<unknown>;
  edoId: string;
}) => {
  const [dropdownSelection, setDropdownSelection] = useState(betweenSelection);
  const { minDays, maxDays, minOffset, maxOffset } = deliveryOption;

  const updateMinDays = useCallback(
    (minDays, edoId) => {
      dispatch(setField({ edoId, field: 'minDays', value: minDays }));
    },
    [dispatch],
  );
  const updateMaxDays = useCallback(
    (maxDays, edoId) => {
      dispatch(setField({ edoId, field: 'maxDays', value: maxDays }));
    },
    [dispatch],
  );
  const updateMinOffset = useCallback(
    (minOffset, edoId) => {
      dispatch(setField({ edoId, field: 'minOffset', value: minOffset }));
    },
    [dispatch],
  );
  const updateMaxOffset = useCallback(
    (maxOffset, edoId) => {
      dispatch(setField({ edoId, field: 'maxOffset', value: maxOffset }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (Number(minDays) >= 0 || Number(maxDays) >= 0) {
      setDropdownSelection(betweenSelection);
    }
    if (Number(minOffset) >= 0 || Number(maxOffset) >= 0) {
      setDropdownSelection(betweenOffsetSelection);
    }
  }, [maxDays, maxOffset, minDays, minOffset]);

  const getTextFields = () => {
    const getValidationState = (min?: string | number, max?: string | number) => {
      const isEmptyField = (val?: string | number) => isNil(val) || val === '';

      const isBothEmpty = isEmptyField(min) && isEmptyField(max);
      const isMinGreaterThanMax = !isEmptyField(min) && !isEmptyField(max) && Number(min) > Number(max);

      return {
        isMinInvalid: isBothEmpty || isMinGreaterThanMax || Number(min) < 0,
        isMaxInvalid: isBothEmpty || isMinGreaterThanMax || Number(max) < 0,
      };
    };

    switch (dropdownSelection?.value) {
      case betweenDays:
        const { isMinInvalid: isMinDaysInvalid, isMaxInvalid: isMaxDaysInvalid } = getValidationState(minDays, maxDays);

        return (
          <>
            <NumberField
              label="Min"
              value={minDays}
              onChange={e => updateMinDays(e.target.value, edoId)}
              isInvalid={isMinDaysInvalid}
            />
            <p>and</p>
            <NumberField
              label="Max"
              value={maxDays}
              onChange={e => updateMaxDays(e.target.value, edoId)}
              isInvalid={isMaxDaysInvalid}
            />
            <p>business days</p>
          </>
        );
      case betweenOffset:
        const { isMinInvalid: isMinOffsetInvalid, isMaxInvalid: isMaxOffsetInvalid } = getValidationState(
          minOffset,
          maxOffset,
        );

        return (
          <>
            <NumberField
              label="Min"
              value={minOffset}
              onChange={e => updateMinOffset(e.target.value, edoId)}
              isInvalid={isMinOffsetInvalid}
            />
            <p>and</p>
            <NumberField
              label="Max"
              value={maxOffset}
              onChange={e => updateMaxOffset(e.target.value, edoId)}
              isInvalid={isMaxOffsetInvalid}
            />
            <p>business days</p>
          </>
        );
      default:
        return;
    }
  };

  const onDropDownSelect = (selection?: SelectField | null) => {
    selection && setDropdownSelection(selection);
    updateMaxDays(undefined, edoId);
    updateMinDays(undefined, edoId);
    updateMaxOffset(undefined, edoId);
    updateMinOffset(undefined, edoId);
  };

  return (
    <div className={textBoxStyles}>
      <Select
        label="Delivery"
        options={[betweenSelection, betweenOffsetSelection]}
        onChange={onDropDownSelect}
        value={dropdownSelection}
      />
      {getTextFields()}
    </div>
  );
};

export default DeliveryDays;
