import React from 'react';

import { Select } from '@cimpress/react-components';

import { availableTags } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../types/shared';
import { fieldCss } from '../create/tiers/DeliveryTierEditor';

const TagSelect = ({
  tags,
  updateTagSelection,
}: {
  tags: SelectField[];
  updateTagSelection: (selection: SelectField[]) => void;
}) => (
  <div className={fieldCss}>
    <Select
      isClearable
      label="Available Tags"
      // @ts-ignore
      value={tags}
      options={availableTags}
      menuPortalTarget={document.body}
      // @ts-ignore
      onChange={updateTagSelection}
      // @ts-ignore
      isMulti
    />
  </div>
);

export default TagSelect;
