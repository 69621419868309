import React from 'react';

import { Select } from '@cimpress/react-components';

import { useCarrierServiceCapabilities } from '../../../hooks/useCarriers';
import { SelectField } from '../../../types/shared';

export const CarrierServiceCapabilitiesSelect = ({
  values,
  onChange,
  className,
}: {
  values: string[];
  onChange: (data: SelectField[]) => void;
  className: any;
}) => {
  const { isFetching, data: carrierServiceCapabilities } = useCarrierServiceCapabilities();
  const options: SelectField[] =
    carrierServiceCapabilities?.map(({ key, name }: { key: string; name: string }) => ({
      label: name,
      value: key,
    })) ?? [];
  const selectedCarrierServiceCapabilities = values.map(
    value => options.find(option => option.value === value) ?? { label: value, value },
  );
  return (
    <Select
      className={className}
      // @ts-ignore
      isMulti
      label="Carrier Service Capabilities"
      isDisabled={isFetching}
      // @ts-ignore
      value={selectedCarrierServiceCapabilities}
      options={options}
      // @ts-ignore
      onChange={onChange}
    />
  );
};
