import en from './en.json';

export const LANGUAGES = ['en'] as const;

export const DEFAULT_LANGUAGE = 'en';

export type Language = typeof LANGUAGES[number];

const translations = { en };
export default translations;
