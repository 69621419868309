import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { Button } from '@cimpress/react-components';

import { addMapping, moveMapping } from '../../../reducers/deliverygroup/deliveryGroupActions';
import { MappingState } from '../../../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryOptionState } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { draggableItemStyle } from '../../../styles/draggableItemStyle';
import MappingEditor from './MappingEditor';

const DndMappingEditor = ({
  mappings,
  deliveryOptions,
  dispatch,
}: {
  mappings: MappingState[];
  deliveryOptions: DeliveryOptionState[];
  dispatch: React.Dispatch<unknown>;
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      moveMapping({
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      }),
    );
  };

  const isDragDisabled = mappings.length === 1;
  const draggableItems = mappings.map((mapping, index) => (
    <Draggable key={mapping.mappingId} draggableId={mapping.mappingId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={draggableItemStyle(snapshot.isDragging)}
        >
          <MappingEditor mapping={mapping} deliveryOptions={deliveryOptions} dispatch={dispatch} />
        </div>
      )}
    </Draggable>
  ));

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {draggableItems}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button style={{ marginBottom: 20 }} variant="default" onClick={() => dispatch(addMapping())}>
        Add Override
      </Button>
    </div>
  );
};

export default DndMappingEditor;
