import produce from 'immer';

import * as actions from './deliveryGroupActions';
import { initialConsolidation, initialMapping } from './deliveryGroupConstants';

const deliveryGroupReducer = produce((draft, action) => {
  switch (action.type) {
    case actions.SET_FIELD: {
      const { field, value } = action.payload;
      draft[field] = value;
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.ADD_MAPPING: {
      draft.mappings.push(initialMapping());
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.REMOVE_MAPPING: {
      const { mappingId } = action.payload;
      const index = draft.mappings.findIndex((mapping: { mappingId: string }) => mapping.mappingId === mappingId);
      draft.mappings.splice(index, 1);
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.SET_MAPPING_FIELD: {
      const { mappingId, field, value } = action.payload;
      const index = draft.mappings.findIndex((mapping: { mappingId: string }) => mapping.mappingId === mappingId);
      draft.mappings[index][field] = value;
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.MOVE_MAPPING: {
      const { sourceIndex, destinationIndex } = action.payload;
      if (sourceIndex >= 0 && destinationIndex <= draft.mappings.length - 1) {
        move(draft.mappings, sourceIndex, destinationIndex);
      }
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.ADD_CONSOLIDATION: {
      draft.productConsolidations.push(initialConsolidation());
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.REMOVE_CONSOLIDATION: {
      const { consolidationId } = action.payload;
      const index = draft.productConsolidations.findIndex(
        (consolidation: { consolidationId: string }) => consolidation.consolidationId === consolidationId,
      );
      draft.productConsolidations.splice(index, 1);
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.SET_CONSOLIDATION_FIELD: {
      const { consolidationId, field, value } = action.payload;
      const index = draft.productConsolidations.findIndex(
        (consolidation: { consolidationId: string }) => consolidation.consolidationId === consolidationId,
      );
      draft.productConsolidations[index][field] = value;
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.MOVE_CONSOLIDATION: {
      const { sourceIndex, destinationIndex } = action.payload;
      if (sourceIndex >= 0 && destinationIndex <= draft.productConsolidations.length - 1) {
        move(draft.productConsolidations, sourceIndex, destinationIndex);
      }
      draft.hasUnsavedWork = true;
      break;
    }

    case actions.POPULATE_DELIVERY_GROUP: {
      return action.payload;
    }

    default: {
      return draft;
    }
  }
});

const move = (array: unknown[], from: number, to: number) => {
  const element = array[from];
  array.splice(from, 1);
  array.splice(to, 0, element);
};

export default deliveryGroupReducer;
