import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'r5BoGOzZKzxnN5jv4d3wiBG4LKuBiCF2'; // edo manager client

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

export default auth;
