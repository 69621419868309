import { css } from '@emotion/css';
import React, { useReducer, Reducer, useContext, useState } from 'react';
import { useHistory, RouteComponentProps, Switch, Route } from 'react-router-dom';

import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconSynchronizeArrowClock from '@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowClock';
import { Button, Card, FourOhFour, InlineEdit, Tooltip } from '@cimpress/react-components';

import hydrateDeliveryGroupResponse from '../../helpers/hydrateDeliveryGroup';
import hydrateDeliveryOption from '../../helpers/hydrateDeliveryOption';
import hydrateDeliveryTier from '../../helpers/hydrateDeliveryTier';
import { validateStates } from '../../helpers/validateStates';
import useBatchUpdate from '../../hooks/edit/useBatchUpdate';
import useCloneDeliveryGroup from '../../hooks/edit/useCloneDeliveryGroup';
import useDeleteDeliveryGroup from '../../hooks/edit/useDeleteDeliveryGroup';
import useDeliveryGroup from '../../hooks/view/useDeliveryGroup';
import useDeliveryOptions from '../../hooks/view/useDeliveryOptions';
import useDeliveryTiers from '../../hooks/view/useDeliveryTiers';
import { populateDeliveryGroup, setField } from '../../reducers/deliverygroup/deliveryGroupActions';
import { DeliveryGroupState, initialDeliveryGroup } from '../../reducers/deliverygroup/deliveryGroupConstants';
import deliveryGroupReducer from '../../reducers/deliverygroup/deliveryGroupReducer';
import { populateDeliveryOptions } from '../../reducers/deliveryoption/deliveryOptionActions';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import deliveryOptionReducer from '../../reducers/deliveryoption/deliveryOptionReducer';
import { populateDeliveryTiers } from '../../reducers/deliverytier/deliveryTierActions';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import deliveryTierReducer from '../../reducers/deliverytier/deliveryTierReducer';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';
import { HydratedDeliveryOptionResponse } from '../../types/deliveryOption';
import { DeliveryTierResponse } from '../../types/deliveryTier';
import LineBreak from '../shared/LineBreak';
import Loading from '../shared/Loading';
import { SnackBarContext } from '../snackbarContext';
import AuditLogPage from './AuditLogPage';
import DeletePrompt from './DeletePrompt';
import EditPage from './EditPage';

const buttonCss = css`
  display: flex;
  justify-content: space-between;
`;

const ViewPage = ({ match }: RouteComponentProps<{ deliveryGroupId: string }>) => {
  const { deliveryGroupId } = match.params;
  const history = useHistory();
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);

  const [isGroupDataHydrated, setIsGroupDataHydrated] = useState<boolean>(false);
  const [isOptionDataHydrated, setIsOptionDataHydrated] = useState<boolean>(false);
  const [isTierDataHydrated, setIsTierDataHydrated] = useState<boolean>(false);

  const [deliveryGroup, deliveryGroupDispatch] = useReducer<Reducer<DeliveryGroupState, unknown>>(
    deliveryGroupReducer,
    initialDeliveryGroup(),
  );
  const [deliveryOptions, deliveryOptionsDispatch] = useReducer<Reducer<DeliveryOptionState[], unknown>>(
    deliveryOptionReducer,
    [],
  );
  const [deliveryTiers, deliveryTiersDispatch] = useReducer<Reducer<DeliveryTierState[], unknown>>(
    deliveryTierReducer,
    [],
  );

  const { isLoading: isLoadingDeliveryGroup, isError: isDeliveryGroupError } = useDeliveryGroup({
    id: deliveryGroupId,
    onSuccess: (data: DeliveryGroupResponse & { etag: string; accountName: string }) => {
      const deliveryGroupState = hydrateDeliveryGroupResponse(data);
      deliveryGroupDispatch(populateDeliveryGroup(deliveryGroupState));
      setIsGroupDataHydrated(true);
    },
  });

  const { isLoading: isLoadingDeliveryOptions, isError: isDeliveryOptionsError } = useDeliveryOptions({
    deliveryGroupId,
    onSuccess: (data: HydratedDeliveryOptionResponse[]) => {
      const deliveryOptionsState: DeliveryOptionState[] = data.map(deliveryOptionResponse =>
        hydrateDeliveryOption(deliveryOptionResponse),
      );
      deliveryOptionsDispatch(populateDeliveryOptions(deliveryOptionsState));
      setIsOptionDataHydrated(true);
    },
  });

  const { isLoading: isLoadingDeliveryTiers, isError: isDeliveryTiersError } = useDeliveryTiers({
    deliveryGroupId,
    onSuccess: (data: DeliveryTierResponse[]) => {
      const deliveryTiersState: DeliveryTierState[] = data.map(deliveryTierResponse =>
        hydrateDeliveryTier(deliveryTierResponse),
      );
      deliveryTiersDispatch(populateDeliveryTiers(deliveryTiersState));
      setIsTierDataHydrated(true);
    },
  });

  const { mutate: batchUpdate, isLoading: isUpdating } = useBatchUpdate({
    deliveryGroupId,
    onSuccess: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'success',
        details: 'Successfully updated the delivery group.',
      });
      history.push(`/groups/${deliveryGroupId}`);
    },
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'danger',
        details: 'Failed to update the delivery group. Please try again.',
      });
    },
  });

  const { mutate: deleteDeliveryGroup, isLoading: isDeleting } = useDeleteDeliveryGroup({
    deliveryGroupId,
    onSuccess: () => history.push('/'),
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({ type: 'danger', details: 'Error deleting ecommerce delivery group.' });
    },
  });

  const { mutate: cloneDeliveryGroup, isLoading: isCloning } = useCloneDeliveryGroup({
    onSuccess: data => history.push(`/groups/${data.id}`),
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({ type: 'danger', details: 'Error cloning ecommerce delivery group.' });
    },
  });

  if (isDeliveryGroupError || isDeliveryOptionsError || isDeliveryTiersError) {
    return (
      <div style={{ paddingTop: '100px' }}>
        <FourOhFour />
      </div>
    );
  }

  if (
    isLoadingDeliveryGroup ||
    isLoadingDeliveryOptions ||
    isLoadingDeliveryTiers ||
    isUpdating ||
    isDeleting ||
    isCloning ||
    !isGroupDataHydrated ||
    !isOptionDataHydrated ||
    !isTierDataHydrated
  ) {
    return <Loading />;
  }

  const { isValid, areRulesValid, errorMessages } = validateStates({
    deliveryGroupState: deliveryGroup,
    deliveryOptionStates: deliveryOptions,
    deliveryTierStates: deliveryTiers,
  });

  return (
    <div className="container">
      <Card
        isMinimal
        header={
          <div style={{ margin: '-15px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Route exact path="/groups/:deliveryGroupId">
              <InlineEdit
                size="h1"
                name="delivery group name"
                required
                value={deliveryGroup?.name}
                onChange={e => deliveryGroupDispatch(setField({ field: 'name', value: e.target.value }))}
              />
            </Route>
            <Route path="/groups/:deliveryGroupId/audit-log">
              <h1>{deliveryGroup?.name}</h1>
            </Route>
            <div style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Tooltip contents="View the audit log for this delivery group">
                <Button
                  variant="link"
                  aria-label="Audit Log"
                  onClick={() => history.push(`/groups/${deliveryGroupId}/audit-log`)}
                  icon={<IconSynchronizeArrowClock size="lg" />}
                />
              </Tooltip>
              <Tooltip contents="Create a cloned copy of this delivery group">
                <Button
                  variant="link"
                  aria-label="Copy"
                  onClick={() => cloneDeliveryGroup({ id: deliveryGroupId, name: `${deliveryGroup.name} (Copy)` })}
                  icon={<IconCopy size="lg" />}
                />
              </Tooltip>
              <Button
                variant="link"
                aria-label="Delete"
                onClick={() => setShowDeletePrompt(true)}
                icon={<IconBin size="lg" />}
              />
            </div>
          </div>
        }
      >
        <DeletePrompt
          text={'Are you sure you want to delete this delivery group?'}
          show={showDeletePrompt}
          onDelete={() => deleteDeliveryGroup({ id: deliveryGroupId, etag: deliveryGroup.etag! })}
          onCancel={() => setShowDeletePrompt(false)}
        />
        <Switch>
          <Route exact path="/groups/:deliveryGroupId">
            <>
              <EditPage
                displayGroupName={false}
                areRulesValid={areRulesValid}
                deliveryGroup={deliveryGroup}
                deliveryGroupDispatch={deliveryGroupDispatch}
                deliveryOptions={deliveryOptions.filter(option => !option.isDeleted)}
                deliveryOptionsDispatch={deliveryOptionsDispatch}
                deliveryTiers={deliveryTiers.filter(option => !option.isDeleted)}
                deliveryTiersDispatch={deliveryTiersDispatch}
              />
              <LineBreak />
              <div className={buttonCss}>
                <Button variant="default" style={{ marginRight: '10px' }} onClick={() => history.push('/')}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    batchUpdate({
                      deliveryGroupState: deliveryGroup,
                      etag: deliveryGroup.etag!,
                      deliveryOptionStates: deliveryOptions,
                      deliveryTierStates: deliveryTiers,
                    })
                  }
                  disabled={!isValid}
                >
                  {isValid ? (
                    'Update'
                  ) : (
                    <Tooltip
                      contents={errorMessages.map((msg, idx) => (
                        <p key={idx}>{msg}</p>
                      ))}
                    >
                      Update
                    </Tooltip>
                  )}
                </Button>
              </div>
            </>
          </Route>
          <Route path="/groups/:deliveryGroupId/audit-log">
            <>
              <AuditLogPage
                deliveryGroup={deliveryGroup}
                deliveryOptions={deliveryOptions.filter(option => !option.isDeleted)}
                deliveryTiers={deliveryTiers.filter(option => !option.isDeleted)}
              />
              <div
                style={{ position: 'sticky', bottom: 0, paddingBottom: '16px', background: '#ffffff', width: '100%' }}
              >
                <LineBreak />
                <div className={buttonCss}>
                  <Button
                    variant="default"
                    style={{ marginRight: '10px' }}
                    onClick={() => history.push(`/groups/${deliveryGroupId}`)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </>
          </Route>
        </Switch>
      </Card>
    </div>
  );
};

export default ViewPage;
