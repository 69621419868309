import { v4 as uuid } from 'uuid';

import { DeliveryGroupState, MappingState } from '../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryGroupResponse } from '../types/deliveryGroup';

const hydrateDeliveryGroupResponse = (
  data: DeliveryGroupResponse & { etag: string; accountName: string },
): DeliveryGroupState => {
  const mappings: MappingState[] = data.mappings.map(mapping => ({
    mappingId: uuid(),
    name: mapping.name,
    attributeModelUrl: mapping.attributeModelUrl,
    skus: mapping.skus,
    edoIds: mapping.ecommerceDeliveryOptions.map(edo => edo.id),
  }));

  return {
    id: data.id,
    name: data.name,
    mappings,
    defaultEdoIds: data.defaultEcommerceDeliveryOptions.ecommerceDeliveryOptions.map(defaultEdo => defaultEdo.id),
    account: {
      label: data.accountName,
      value: data.account.id,
    },
    timeZone: {
      label: data.timeZone,
      value: data.timeZone,
    },
    calendarUrl: data._links.calendar?.href,
    consolidateCart: data.consolidateCart,
    productConsolidations:
      data.productConsolidations?.map(consolidation => ({
        ...consolidation,
        consolidationId: uuid(),
      })) ?? [],
    etag: data.etag,
    hasUnsavedWork: false,
  };
};

export default hydrateDeliveryGroupResponse;
