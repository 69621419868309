import { v4 as uuid } from 'uuid';

import { DisplayNameState, earliest } from '../deliveryoption/deliveryOptionConstants';

export type DeliveryTierState = {
  id: string;
  name?: string;
  displayNames: DisplayNameState[];
  tags: string[];
  dateSelection: string;
  hasUnsavedWork: boolean;
  isNewTier: boolean;
  isDeleted: boolean;
  isValid: boolean;
};

export const initialDeliveryTier = () => {
  return {
    id: uuid(),
    name: undefined,
    displayNames: [],
    tags: [],
    dateSelection: earliest,
    hasUnsavedWork: true,
    isNewTier: true,
    isDeleted: false,
    isValid: false,
  };
};
