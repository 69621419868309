import { cx, css } from '@emotion/css';
import React from 'react';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { Select, RadioGroup, Radio, Tooltip, colors } from '@cimpress/react-components';

import { useCarrierServices } from '../../../hooks/useCarriers';
import { SelectField } from '../../../types/shared';

const carrierContainerStyle = css`
  display: grid;
  grid-template-columns: 60% 25% 2%;
  column-gap: 20px;
`;

const toolTipStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const CarrierServicesSelect = ({
  className,
  value,
  required,
  onChange,
  onChangeRequirement,
}: {
  value: string[];
  required: boolean;
  onChange: (selectedCarrierServices: SelectField[]) => void;
  onChangeRequirement: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className: any;
}) => {
  const { isFetching, data: carrierServices } = useCarrierServices();
  const options = carrierServices?.map(({ key, name }) => ({ label: name, value: key }));
  const selectedCarrierServices = options?.filter(option => value.includes(option.value));
  return (
    <div className={cx(className, carrierContainerStyle)}>
      <Select
        // @ts-ignore
        isMulti
        label="Carrier Services"
        isDisabled={isFetching}
        // @ts-ignore
        value={selectedCarrierServices}
        options={options}
        // @ts-ignore
        onChange={onChange}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // fixes issue where dropdown list overlaps with other components in page
      />
      <RadioGroup name="carrierTypeRadio" onChange={onChangeRequirement} defaultSelected={required}>
        <Radio label="Specific" value={true} />
        <Radio label="Flexible" value={false} />
      </RadioGroup>
      <div className={toolTipStyle}>
        <Tooltip contents="Must use one of the selected carriers for date calculations and fulfillment">
          <IconInformationCircle weight="fill" color={colors.info.base} />
        </Tooltip>
        <Tooltip contents="The selected carriers are preferred, but if not possible, use an alternative carrier for fulfillment.">
          <IconInformationCircle weight="fill" color={colors.info.base} />
        </Tooltip>
      </div>
    </div>
  );
};
