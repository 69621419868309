// EDO list-level actions
import { DeliveryOptionState } from './deliveryOptionConstants';

export const ADD_DELIVERY_OPTION = 'ADD_DELIVERY_OPTION';
export const REMOVE_DELIVERY_OPTION = 'REMOVE_DELIVERY_OPTION';
export const POPULATE_DELIVERY_OPTIONS = 'POPULATE_DELIVERY_OPTIONS';

// EDO-level actions
export const VALIDATE_DELIVERY_OPTION = 'VALIDATE_DELIVERY_OPTION';
export const SET_FIELD = 'SET_FIELD';
export const ADD_DESTINATION = 'ADD_DESTINATION';
export const REMOVE_DESTINATION = 'REMOVE_DESTINATION';
export const SET_DESTINATION_FIELD = 'SET_DESTINATION_FIELD';

export const ADD_POSTAL_CODE_RANGE = 'ADD_POSTAL_CODE_RANGE';
export const REMOVE_POSTAL_CODE_RANGE = 'REMOVE_POSTAL_CODE_RANGE';
export const SET_POSTAL_CODE_RANGE_FIELD = 'SET_POSTAL_CODE_RANGE_FIELD';

export const ADD_DISPLAY_NAME = 'ADD_DISPLAY_NAME';
export const REMOVE_DISPLAY_NAME = 'REMOVE_DISPLAY_NAME';
export const SET_DISPLAY_NAME_FIELD = 'SET_DISPLAY_NAME_FIELD';

type EdoPayload = {
  edoId: string;
};

type FieldPayload = {
  edoId: string;
  field?: string;
  value?: unknown;
};

type DestinationPayload = FieldPayload & {
  destinationId: string;
};

type PostalCodePayload = DestinationPayload & {
  postalCodeRangeId: string;
};

type DisplayNamePayload = {
  resourceId: string;
  displayNameId: string;
  field?: string;
  value?: unknown;
};

export const addDeliveryOption = (payload?: { deliveryTierId: string }) => ({ type: ADD_DELIVERY_OPTION, payload });
export const removeDeliveryOption = (payload: EdoPayload) => ({ type: REMOVE_DELIVERY_OPTION, payload });
export const populateDeliveryOptions = (payload: DeliveryOptionState[]) => ({
  type: POPULATE_DELIVERY_OPTIONS,
  payload,
});

export const validateDeliveryOption = (payload: EdoPayload & { isValid: boolean }) => ({
  type: VALIDATE_DELIVERY_OPTION,
  payload,
});
export const setField = (payload: FieldPayload) => ({ type: SET_FIELD, payload });
export const addDestination = (payload: EdoPayload) => ({ type: ADD_DESTINATION, payload });

export const removeDestination = (payload: DestinationPayload) => ({
  type: REMOVE_DESTINATION,
  payload,
});
export const setDestinationField = (payload: DestinationPayload) => ({
  type: SET_DESTINATION_FIELD,
  payload,
});

export const addPostalCodeRange = (payload: DestinationPayload) => ({
  type: ADD_POSTAL_CODE_RANGE,
  payload,
});
export const removePostalCodeRange = (payload: PostalCodePayload) => ({ type: REMOVE_POSTAL_CODE_RANGE, payload });
export const setPostalCodeRangeField = (payload: PostalCodePayload) => ({ type: SET_POSTAL_CODE_RANGE_FIELD, payload });

// shared between options and tiers
export const addDisplayName = (payload: { resourceId: string }) => ({ type: ADD_DISPLAY_NAME, payload });
export const removeDisplayName = (payload: DisplayNamePayload) => ({
  type: REMOVE_DISPLAY_NAME,
  payload,
});
export const setDisplayNameField = (payload: DisplayNamePayload) => ({
  type: SET_DISPLAY_NAME_FIELD,
  payload,
});
