import { useQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { Filters } from '../../components/view/AuditLogPage';
import { DeliveryGroupState } from '../../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { getDataFromResponses } from '../../reducers/shared';
import { getDeliveryOptionVersions } from '../../services/deliveryOptions';

const useDeliveryOptionVersions = ({
  deliveryGroup,
  deliveryOptions,
  filters,
}: {
  deliveryGroup: DeliveryGroupState;
  deliveryOptions: DeliveryOptionState[];
  filters: Filters;
}) => {
  const { id: deliveryGroupId } = deliveryGroup;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryResult = useQueries({
    queries:
      deliveryGroupId && deliveryOptions.length > 0
        ? deliveryOptions.map(option => {
            return {
              queryKey: ['delivery-option-versions', { deliveryGroupId, optionId: option.id, ...filters }],
              queryFn: () =>
                getDeliveryOptionVersions({
                  deliveryGroupId,
                  deliveryOptionId: option.id,
                  filters,
                }),
            };
          })
        : [],
  });

  useEffect(() => {
    setIsLoading(prevState => {
      const hasQueriesLoading = queryResult.some(query => query.isLoading === true);
      if (hasQueriesLoading !== prevState) {
        return hasQueriesLoading;
      }
      return prevState;
    });
  }, [queryResult]);

  if (deliveryOptions.length === 0) {
    return {
      queryResult: { responses: [], errors: [] },
      isLoading: false,
    };
  }

  return {
    queryResult: getDataFromResponses(queryResult),
    isLoading,
  };
};

export default useDeliveryOptionVersions;
