export const AuditLogError = ({ noResults = false }: { noResults?: boolean }) => {
  const action = (
    <>
      Please try reloading the page or reach out to the Dispatch Squad on Slack in the{' '}
      <a href="https://cimpress.slack.com/archives/C7CNT9E2Z" target="_blank" rel="noreferrer">
        #mcp-platform-support channel
      </a>{' '}
      or by email at <a href="mailto:DispatchSquad@cimpress.com">DispatchSquad@cimpress.com</a>.
    </>
  );

  if (noResults) {
    return <p>There was an error loading the audit log details. {action}</p>;
  } else {
    return <p>There was an error loading some of the audit log details, so the table below is incomplete. {action}</p>;
  }
};
