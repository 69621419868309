import { useQuery } from '@tanstack/react-query';

import { getDeliveryTiers } from '../../services/deliveryTiers';
import { DeliveryTierResponse } from '../../types/deliveryTier';

const useDeliveryTiers = ({
  deliveryGroupId,
  onError,
  onSuccess,
  enabled = true,
}: {
  deliveryGroupId: string;
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryTierResponse[]) => void;
  enabled?: boolean;
}) => {
  return useQuery(['delivery-tiers', { id: deliveryGroupId }], () => getDeliveryTiers({ deliveryGroupId }), {
    enabled,
    onError,
    onSuccess,
  });
};

export default useDeliveryTiers;
