import { useQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { Filters } from '../../components/view/AuditLogPage';
import { DeliveryGroupState } from '../../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import { getDataFromResponses } from '../../reducers/shared';
import { getDeliveryTierVersions } from '../../services/deliveryTiers';

const useDeliveryTierVersions = ({
  deliveryGroup,
  deliveryTiers,
  filters,
}: {
  deliveryGroup: DeliveryGroupState;
  deliveryTiers: DeliveryTierState[];
  filters: Filters;
}) => {
  const { id: deliveryGroupId } = deliveryGroup;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryResult = useQueries({
    queries:
      deliveryGroupId && deliveryTiers.length > 0
        ? deliveryTiers.map(tier => {
            return {
              queryKey: ['delivery-tier-versions', { deliveryGroupId, deliveryTierId: tier.id, ...filters }],
              queryFn: () => getDeliveryTierVersions({ deliveryGroupId, deliveryTierId: tier.id, filters }),
            };
          })
        : [],
  });

  useEffect(() => {
    setIsLoading(prevState => {
      const hasQueriesLoading = queryResult.some(query => query.isLoading === true);

      if (hasQueriesLoading !== prevState) {
        return hasQueriesLoading;
      }
      return prevState;
    });
  }, [queryResult]);

  if (deliveryTiers.length === 0) {
    return {
      queryResult: { responses: [], errors: [] },
      isLoading: false,
    };
  }

  return {
    queryResult: getDataFromResponses(queryResult),
    isLoading,
  };
};

export default useDeliveryTierVersions;
