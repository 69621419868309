import { css } from '@emotion/css';
import React, { useState, useReducer, useContext, Reducer } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Card, Select, Stepper, Step, TextField, Tooltip } from '@cimpress/react-components';

import { validateStates } from '../../helpers/validateStates';
import useBatchUpdate from '../../hooks/edit/useBatchUpdate';
import useCreateDeliveryGroup from '../../hooks/edit/useCreateDeliveryGroup';
import { useAccounts } from '../../hooks/useAccounts';
import { setField } from '../../reducers/deliverygroup/deliveryGroupActions';
import { DeliveryGroupState, initialDeliveryGroup } from '../../reducers/deliverygroup/deliveryGroupConstants';
import deliveryGroupReducer from '../../reducers/deliverygroup/deliveryGroupReducer';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import deliveryOptionReducer from '../../reducers/deliveryoption/deliveryOptionReducer';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import deliveryTierReducer from '../../reducers/deliverytier/deliveryTierReducer';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';
import { SelectField } from '../../types/shared';
import LineBreak from '../shared/LineBreak';
import Loading from '../shared/Loading';
import { SnackBarContext } from '../snackbarContext';
import EditPage from '../view/EditPage';

const stepperStyle = css`
  margin: auto;
  width: 80%;
`;

const fieldDisplayCss = css`
  width: 100%;
  max-width: 500px;
`;

const buttonCss = css`
  display: flex;
  justify-content: space-between;
`;

const CreationPage = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { setShowSnackbar, setSnackbarDetails } = useContext(SnackBarContext);

  const [deliveryGroup, deliveryGroupDispatch] = useReducer<Reducer<DeliveryGroupState, unknown>>(
    deliveryGroupReducer,
    initialDeliveryGroup(),
  );
  const [deliveryOptions, deliveryOptionsDispatch] = useReducer<Reducer<DeliveryOptionState[], unknown>>(
    deliveryOptionReducer,
    [],
  );
  const [deliveryTiers, deliveryTiersDispatch] = useReducer<Reducer<DeliveryTierState[], unknown>>(
    deliveryTierReducer,
    [],
  );

  const { isLoading: isLoadingAccounts, data: accounts } = useAccounts({
    filter: true,
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'danger',
        details: 'Failed to load accounts. Please refresh the page.',
      });
    },
  });

  const { mutate: createDeliveryGroup, isLoading: isCreating } = useCreateDeliveryGroup({
    onSuccess: (data: DeliveryGroupResponse & { etag: string }) => {
      setActiveStep(activeStep + 1);
      deliveryGroupDispatch(setField({ field: 'id', value: data.id }));
      deliveryGroupDispatch(setField({ field: 'etag', value: data.etag }));
    },
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'danger',
        details: 'Failed to create delivery group.',
      });
    },
  });

  const { mutate: batchUpdate, isLoading: isUpdating } = useBatchUpdate({
    deliveryGroupId: deliveryGroup.id!,
    onSuccess: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'success',
        details: 'Successfully created the delivery group.',
      });
      history.push(`/groups/${deliveryGroup.id}`);
    },
    onError: () => {
      setShowSnackbar(true);
      setSnackbarDetails({
        type: 'danger',
        details: 'Failed to finish delivery group creation.',
      });
    },
  });

  const canProceed = deliveryGroup?.name && deliveryGroup?.account?.value;
  const { isValid, areRulesValid, errorMessages } = validateStates({
    deliveryGroupState: deliveryGroup,
    deliveryOptionStates: deliveryOptions,
    deliveryTierStates: deliveryTiers,
  });

  const nextButtonMsg = canProceed ? (
    'Next'
  ) : (
    <Tooltip contents="Delivery group name and account are required">Next</Tooltip>
  );

  const createButtonMsg = isValid ? (
    'Create'
  ) : (
    <Tooltip
      contents={errorMessages.map((msg, idx) => (
        <p key={idx}>{msg}</p>
      ))}
    >
      Create
    </Tooltip>
  );

  if (isLoadingAccounts || isCreating || isUpdating) {
    return <Loading />;
  }

  const onCreateDeliveryGroup = () => {
    const name = deliveryGroup.name;
    const accountId = deliveryGroup?.account?.value;
    name && accountId && createDeliveryGroup({ name, accountId });
  };

  const onUpdateDeliveryGroup = () => {
    batchUpdate({
      deliveryGroupState: deliveryGroup,
      etag: deliveryGroup.etag!,
      deliveryOptionStates: deliveryOptions,
      deliveryTierStates: deliveryTiers,
    });
  };

  const isInitialStep = activeStep === 0;
  return (
    <div className="container">
      <Card isMinimal>
        <div className={stepperStyle}>
          <Stepper activeStep={activeStep} status="success">
            <Step>Create delivery group</Step>
            <Step>Configure delivery group details</Step>
          </Stepper>
        </div>
        {isInitialStep ? (
          <div>
            <TextField
              className={fieldDisplayCss}
              label="Delivery Group Name"
              required
              value={deliveryGroup.name}
              onChange={e => deliveryGroupDispatch(setField({ field: 'name', value: e.target.value }))}
            />
            <Select
              className={fieldDisplayCss}
              label="Related Account or Business"
              value={deliveryGroup.account}
              options={accounts && accounts.map(({ id, name }) => ({ label: name, value: id }))}
              onChange={(account?: SelectField | null) =>
                deliveryGroupDispatch(setField({ field: 'account', value: account }))
              }
              required
            />
          </div>
        ) : (
          <EditPage
            areRulesValid={areRulesValid}
            deliveryGroup={deliveryGroup}
            deliveryGroupDispatch={deliveryGroupDispatch}
            deliveryOptions={deliveryOptions}
            deliveryOptionsDispatch={deliveryOptionsDispatch}
            deliveryTiers={deliveryTiers}
            deliveryTiersDispatch={deliveryTiersDispatch}
          />
        )}
        <LineBreak />
        <div className={buttonCss}>
          <Button variant="default" style={{ marginRight: '10px' }} onClick={() => history.push('/')}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => (isInitialStep ? onCreateDeliveryGroup() : onUpdateDeliveryGroup())}
            disabled={isInitialStep ? !canProceed : !isValid}
          >
            {isInitialStep ? nextButtonMsg : createButtonMsg}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default CreationPage;
