import { css } from '@emotion/css';
import isNil from 'lodash/isNil';
import { Fragment } from 'react';

import { setField } from '../../../reducers/deliveryoption/deliveryOptionActions';
import { DeliveryOptionState } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import NumberField from '../../shared/NumberField';

const textBoxStyles = css`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
  .form-group:first-of-type {
    flex: 1;
    max-width: 100px;
  }
  .form-group:not(:first-of-type) {
    margin-left: 10px;
    flex: 1;
    max-width: 100px;
  }
`;

const FallbackDays = ({
  deliveryOption,
  dispatch,
  edoId,
}: {
  deliveryOption: DeliveryOptionState;
  dispatch: React.Dispatch<unknown>;
  edoId: string;
}) => {
  const { minFallbackDays, maxFallbackDays } = deliveryOption;

  const updateMinFallbackDays = (minFallbackDays?: string | number) => {
    dispatch(setField({ edoId, field: 'minFallbackDays', value: minFallbackDays }));
  };

  const updateMaxFallbackDays = (maxFallbackDays?: string | number) => {
    dispatch(setField({ edoId, field: 'maxFallbackDays', value: maxFallbackDays }));
  };

  const getTextFields = () => {
    const getValidationState = (min?: string | number, max?: string | number) => {
      const isEmptyField = (val?: string | number) => isNil(val) || val === '';

      const minNotSetWithMax = isEmptyField(min) && !isEmptyField(max);
      const maxNotSetWithMin = !isEmptyField(min) && isEmptyField(max);
      const isMinGreaterThanMax = !isEmptyField(min) && !isEmptyField(max) && Number(min) > Number(max);

      return {
        isMinInvalid: minNotSetWithMax || isMinGreaterThanMax || Number(min) < 0,
        isMaxInvalid: maxNotSetWithMin || isMinGreaterThanMax || Number(max) < 0,
      };
    };
    const { isMinInvalid: isMinFallbackDaysInvalid, isMaxInvalid: isMaxFallbackDaysInvalid } = getValidationState(
      minFallbackDays,
      maxFallbackDays,
    );

    return (
      <Fragment>
        <NumberField
          label="Minimum"
          value={minFallbackDays}
          onChange={e => updateMinFallbackDays(e.target.value)}
          isInvalid={isMinFallbackDaysInvalid}
        />
        <p>and</p>
        <NumberField
          label="Maximum"
          value={maxFallbackDays}
          onChange={e => updateMaxFallbackDays(e.target.value)}
          isInvalid={isMaxFallbackDaysInvalid}
        />
        <p>business days</p>
      </Fragment>
    );
  };

  return <div className={textBoxStyles}>{getTextFields()}</div>;
};

export default FallbackDays;
