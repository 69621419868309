import { css } from '@emotion/css';

import { colors } from '@cimpress/react-components';

export const draggableStyle = (isDragging: boolean) => css`
  user-select: none;
  border: ${isDragging ? `2px dotted ${colors.teal.base}` : ''};
  box-shadow: ${isDragging ? '5px 5px 5px grey' : ''};
  :hover {
    outline: 2px dotted ${colors.teal.base};
  }
`;

export const draggableItemStyle = (isDragging: boolean): any => css`
  ${draggableStyle(isDragging)};
  background: ${colors.silver};
`;
