import { css } from '@emotion/css';
import React from 'react';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { colors, Tooltip } from '@cimpress/react-components';

const infoCss = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InformationHeader = ({
  header,
  contents,
  isWarning = false,
  size = 'sm',
}: {
  header: string;
  contents?: string;
  isWarning?: boolean;
  size?: 'sm' | 'lg';
}) => (
  <div className={infoCss}>
    {size === 'sm' ? <h5>{header}</h5> : <h4>{header}</h4>}
    {contents && (
      <Tooltip contents={contents}>
        <IconInformationCircle weight="fill" color={isWarning ? colors.danger.base : colors.info.base} />
      </Tooltip>
    )}
  </div>
);

export default InformationHeader;
