import { DeliveryGroupVersion, DeliveryGroupVersionResponse } from '../../types/deliveryGroup';
import { DeliveryOptionVersion, DeliveryOptionVersionResponse } from '../../types/deliveryOption';
import { DeliveryTierVersion, DeliveryTierVersionResponse } from '../../types/deliveryTier';

export enum DeliveryVersionActionTypes {
  ADD_VERSIONS = 'ADD_VERSIONS',
}

export type VersionResponse =
  | (DeliveryGroupVersionResponse & { etag: string })
  | (DeliveryOptionVersionResponse & { etag: string })[]
  | (DeliveryTierVersionResponse & { etag: string })[];

export type Version = DeliveryGroupVersion | DeliveryOptionVersion | DeliveryTierVersion;

type VersionResponsePayload = {
  response: VersionResponse;
  currentVersionState?: {
    versions: Version[];
  };
};

export const populateDeliveryVersions = (payload: VersionResponsePayload) => ({
  type: DeliveryVersionActionTypes.ADD_VERSIONS,
  payload,
});
