import { css } from '@emotion/css';

import { CreateButton } from '../create/CreateButton';

const wrapperStyles = css`
  position: absolute;
  left: 0;
  top: 47px; /* Want it to display below table header, so this is header height */
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.75rem;
  }
`;

export const CreateEdo = (props: any, hasPermission: boolean) => {
  const { loading } = props;

  if (loading) {
    return null;
  }

  return (
    <div className={wrapperStyles}>
      <p>You don't have any Ecommerce Delivery Options (EDOs) configured yet.</p>
      <CreateButton hasPermission={hasPermission} />
    </div>
  );
};
