import { css } from '@emotion/css';
import { countries } from 'countries-list';
import flatMap from 'lodash/flatMap';
import React from 'react';

import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { Button, Select, TextField } from '@cimpress/react-components';

import { setDisplayNameField, removeDisplayName } from '../../reducers/deliveryoption/deliveryOptionActions';
import { DisplayNameState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../types/shared';
import MerchandizingTextInput from '../create/options/MerchandizingTextInput';

const textBoxStyles = css`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
  .form-group:first-of-type {
    flex: 1;
    max-width: 125px;
  }
  .form-group:not(:first-of-type) {
    margin-left: 10px;
    flex: 1;
    max-width: 225px;
  }
`;

const locales: string[] = flatMap(countries, (country, countryCode) =>
  country.languages.map(languageCode => `${languageCode}-${countryCode}`),
);

// For some reason, es-US is no longer in the countries-list package, so we need to add it
locales.push('es-US');

const localeOptions: SelectField[] = locales.map(locale => ({ label: locale, value: locale }));

const DisplayNameEditor = ({
  resourceId,
  displayName,
  selectedLocales,
  dispatch,
}: {
  resourceId: string;
  displayName: DisplayNameState;
  selectedLocales: string[];
  dispatch: React.Dispatch<unknown>;
}) => {
  const displayNameId = displayName.id;
  const updateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDisplayNameField({
        resourceId,
        displayNameId,
        field: 'name',
        value: event.target.value,
      }),
    );
  };

  const updateDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setDisplayNameField({
        resourceId,
        displayNameId,
        field: 'description',
        value: event.target.value,
      }),
    );
  };

  const updateLocale = (selection?: SelectField | null) => {
    dispatch(
      setDisplayNameField({
        resourceId,
        displayNameId,
        field: 'locale',
        value: selection?.value,
      }),
    );
  };

  const updateMerchandizingText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const merchandizingText = event.target.value.split(/[\r\n]/).map(template => ({ template }));

    dispatch(
      setDisplayNameField({
        resourceId,
        displayNameId,
        field: 'merchandizingText',
        value: merchandizingText,
      }),
    );
  };

  const selectedLocale = localeOptions.find(locale => locale.value === displayName.locale);
  const merchandizingTextValue = displayName.merchandizingText?.map(({ template }) => template).join('\n') ?? '';

  const getOptions = () => {
    return localeOptions.map(option => {
      if (selectedLocales.includes(option.value)) {
        // Only allow a locale to be used once per option or tier
        return { ...option, disabled: true };
      }
      return option;
    });
  };

  return (
    <div key={displayNameId}>
      <div className={textBoxStyles}>
        <Select
          label="Locale"
          value={selectedLocale}
          options={getOptions()}
          onChange={updateLocale}
          isOptionDisabled={option => (option as SelectField).disabled ?? false}
        />
        <TextField label="Name" value={displayName.name} onChange={updateName} />
        <TextField label="Description" value={displayName.description} onChange={updateDescription} />
        <Button
          style={{ marginLeft: '10px', marginBottom: '15px' }}
          size="lg"
          variant="link"
          onClick={() => dispatch(removeDisplayName({ resourceId, displayNameId }))}
        >
          <IconBin className="text-info" />
        </Button>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <MerchandizingTextInput
          locale={selectedLocale?.value}
          value={merchandizingTextValue}
          onChange={updateMerchandizingText}
        />
      </div>
    </div>
  );
};

export default DisplayNameEditor;
