import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { Button } from '@cimpress/react-components';

import { addDeliveryOption, setField } from '../../reducers/deliveryoption/deliveryOptionActions';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import { draggableStyle } from '../../styles/draggableItemStyle';
import DeliveryOptionEditor from '../create/options/DeliveryOptionEditor';
import InformationHeader from '../shared/InformationHeader';

const CategorizedDeliveryOptions = ({
  deliveryOptions,
  deliveryOptionsDispatch,
  deliveryTiers,
}: {
  deliveryOptions: DeliveryOptionState[];
  deliveryOptionsDispatch: React.Dispatch<unknown>;
  deliveryTiers: DeliveryTierState[];
}) => {
  const existingDeliveryTierIds = deliveryTiers.map(tier => tier.id);
  const uncategorizedDeliveryOptions = deliveryOptions.filter(
    option => !option.deliveryTierId || !existingDeliveryTierIds.includes(option.deliveryTierId),
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    deliveryOptionsDispatch(
      setField({ edoId: result.draggableId, field: 'deliveryTierId', value: result.destination.droppableId }),
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'uncategorized'} isDropDisabled>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {uncategorizedDeliveryOptions.length > 0 && (
              <>
                <InformationHeader
                  header="Uncategorized"
                  contents="All delivery options must be placed under a tier"
                  isWarning
                  size="lg"
                />
                <p>
                  Please move the below delivery options into an existing delivery tier. If delivery tiers are
                  configured, all delivery options must be placed under a tier.
                </p>
                {uncategorizedDeliveryOptions.map((option, index) => (
                  <Draggable key={option.id} draggableId={option.id} index={index} isDragDisabled={false}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={draggableStyle(snapshot.isDragging)}
                      >
                        <DeliveryOptionEditor
                          key={option.id}
                          deliveryOption={option}
                          dispatch={deliveryOptionsDispatch}
                          edoId={option.id}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {deliveryTiers.map(deliveryTier => (
        <div key={deliveryTier.id} style={{ marginBottom: 20 }}>
          <Droppable droppableId={deliveryTier.id}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div key={deliveryTier.id} style={{ marginBottom: 20 }}>
                  <InformationHeader header={deliveryTier.name || 'Unnamed Tier'} size="lg" />
                  {deliveryOptions
                    .filter(option => option.deliveryTierId === deliveryTier.id)
                    .map((option, index) => (
                      <Draggable key={option.id} draggableId={option.id} index={index} isDragDisabled={false}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={draggableStyle(snapshot.isDragging)}
                          >
                            <DeliveryOptionEditor
                              key={option.id}
                              deliveryOption={option}
                              dispatch={deliveryOptionsDispatch}
                              edoId={option.id}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          <Button
            onClick={() => {
              deliveryOptionsDispatch(addDeliveryOption({ deliveryTierId: deliveryTier.id }));
            }}
          >
            Add Delivery Option
          </Button>
        </div>
      ))}
    </DragDropContext>
  );
};

export default CategorizedDeliveryOptions;
