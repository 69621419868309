import compact from 'lodash/compact';
import concat from 'lodash/concat';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import uniq from 'lodash/uniq';

import {
  DeliveryGroupState,
  MappingState,
  ProductConsolidationState,
} from '../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryOptionState, DisplayNameState } from '../reducers/deliveryoption/deliveryOptionConstants';
import { DeliveryTierState } from '../reducers/deliverytier/deliveryTierConstants';

export const isMappingStateValid = (mapping: MappingState): boolean => {
  const nameNotEmpty = Boolean(mapping.name) && mapping.name?.trim() !== '';
  const hasAttributeModelUrl = Boolean(mapping.attributeModelUrl);
  const hasSkus = compact(mapping.skus).length > 0;
  const hasDeliveryOptions = mapping.edoIds?.length > 0;
  return nameNotEmpty && (hasAttributeModelUrl || hasSkus) && hasDeliveryOptions;
};

export const isProductConsolidationStateValid = (consolidation: ProductConsolidationState): boolean => {
  const hasName = Boolean(consolidation.name) && consolidation.name?.trim() !== '';
  const hasSkus = compact(consolidation.skus).length > 0;
  return hasName && hasSkus;
};

export const validateStates = ({
  deliveryGroupState,
  deliveryOptionStates,
  deliveryTierStates,
}: {
  deliveryGroupState: DeliveryGroupState;
  deliveryOptionStates: DeliveryOptionState[];
  deliveryTierStates: DeliveryTierState[];
}) => {
  const errorMessages = [];
  const hasName = Boolean(deliveryGroupState.name);
  !hasName && errorMessages.push('Delivery group name is required');

  const hasCalendar = Boolean(deliveryGroupState.calendarUrl);
  !hasCalendar && errorMessages.push('Calendar is required');

  const hasTimeZone = !isEmpty(deliveryGroupState.timeZone);
  !hasTimeZone && errorMessages.push('TimeZone is required');

  const mappingsValid = deliveryGroupState.mappings.every(mapping => isMappingStateValid(mapping));
  !mappingsValid && errorMessages.push('Not all mappings are properly configured');

  const hasDefaultDeliveryOptions = deliveryGroupState.defaultEdoIds?.length > 0;
  !hasDefaultDeliveryOptions && errorMessages.push('At least one default delivery option is required');

  const consolidationsValid = deliveryGroupState.productConsolidations.every(consolidation =>
    isProductConsolidationStateValid(consolidation),
  );
  !consolidationsValid && errorMessages.push('Not all product consolidations are properly configured');

  const deliveryOptionIds = deliveryOptionStates.map(edoState => edoState.id);
  const deliveryOptionsInUse = uniq(
    concat(
      flatMap(deliveryGroupState.mappings, mapping => mapping.edoIds),
      deliveryGroupState.defaultEdoIds,
    ),
  );

  const mappedOptionIsNotDeleted = deliveryOptionStates.every(
    edoState => !(deliveryOptionsInUse.includes(edoState.id) && edoState.isDeleted),
  );
  !mappedOptionIsNotDeleted &&
    errorMessages.push('Cannot delete a delivery option that is still used in product rules');

  const notUsingNonExistentOption = deliveryOptionsInUse.every(edoId => deliveryOptionIds.includes(edoId));
  !notUsingNonExistentOption &&
    errorMessages.push('Cannot use a non-existent delivery option to configure product rules');

  const areRulesValid = mappingsValid && consolidationsValid && hasDefaultDeliveryOptions;
  const isDeliveryGroupStateValid =
    areRulesValid && hasName && hasCalendar && hasTimeZone && mappedOptionIsNotDeleted && notUsingNonExistentOption;

  const allDeliveryOptionsValid = deliveryOptionStates.every(edoState => edoState.isDeleted || edoState.isValid);
  !allDeliveryOptionsValid && errorMessages.push('Not all delivery options are properly configured');

  // if there are any tiers configured, all edos must have a delivery tier
  const allDeliveryTiersValid = deliveryTierStates.every(tierState => tierState.isDeleted || tierState.isValid);
  !allDeliveryTiersValid && errorMessages.push('Not all delivery tiers are properly configured');

  // if delivery tiers are configured, all existing delivery options must be associated with a tier
  const allDeliveryOptionsHaveTier =
    isEmpty(deliveryTierStates) ||
    deliveryTierStates.every(tierState => tierState.isDeleted) ||
    deliveryOptionStates.every(edoState => edoState.isDeleted || Boolean(edoState.deliveryTierId));
  !allDeliveryOptionsHaveTier && errorMessages.push('All delivery options must be associated with a delivery tier');

  return {
    isValid:
      isDeliveryGroupStateValid && allDeliveryOptionsValid && allDeliveryTiersValid && allDeliveryOptionsHaveTier,
    areRulesValid,
    errorMessages,
  };
};

export const isDeliveryTierStateValid = (deliveryTierState: DeliveryTierState) => {
  const { name, displayNames } = deliveryTierState;
  const hasName = Boolean(name);
  const displayNamesAreValid = areDisplayNamesValid(displayNames);

  return hasName && displayNamesAreValid;
};

export const isDeliveryOptionStateValid = (deliveryOptionState: DeliveryOptionState) => {
  const {
    maxDays,
    minDays,
    maxOffset,
    minOffset,
    minFallbackDays,
    maxFallbackDays,
    bufferTime,
    name,
    destinations,
    localCutoffTimeHour,
    localCutoffTimeMinute,
    displayNames,
  } = deliveryOptionState;
  const numMaxDays = toNumber(maxDays);
  const numMinDays = toNumber(minDays);
  const numMaxOffset = toNumber(maxOffset);
  const numMinOffset = toNumber(minOffset);
  const numMaxFallbackDays = toNumber(maxFallbackDays);
  const numMinFallbackDays = toNumber(minFallbackDays);

  const isEmptyField = (val?: string | number) => isNil(val) || val === '';

  const maxDaysNotNegative = isEmptyField(maxDays) || numMaxDays >= 0;
  const maxOffsetNotNegative = isEmptyField(maxOffset) || numMaxOffset >= 0;
  const minDaysNotNegative = isEmptyField(minDays) || numMinDays >= 0;
  const minOffsetNotNegative = isEmptyField(minOffset) || numMinOffset >= 0;
  const maxFallbackDaysNotNegative = isEmptyField(maxFallbackDays) || numMaxFallbackDays >= 0;
  const minFallbackDaysNotNegative = isEmptyField(minFallbackDays) || numMinFallbackDays >= 0;
  const allValuesNotNegative =
    maxDaysNotNegative &&
    maxOffsetNotNegative &&
    minDaysNotNegative &&
    minOffsetNotNegative &&
    maxFallbackDaysNotNegative &&
    minFallbackDaysNotNegative;

  const maxDaysGreaterThanMinDays = isEmptyField(maxDays) || isEmptyField(minDays) || numMaxDays >= numMinDays;
  const maxOffsetGreaterThanMinOffset =
    isEmptyField(maxOffset) || isEmptyField(minOffset) || numMaxOffset >= numMinOffset;
  const maxFallbackDaysGreaterThanMinDays =
    (!isEmptyField(minFallbackDays) && !isEmptyField(maxFallbackDays) && numMaxFallbackDays >= numMinFallbackDays) ||
    (isEmptyField(minFallbackDays) && isEmptyField(maxFallbackDays));

  const validDeliveryDays =
    !isEmptyField(maxDays) || !isEmptyField(minDays) || !isEmptyField(maxOffset) || !isEmptyField(minOffset);

  const bufferTimeNonNegative = isNil(bufferTime) || bufferTime >= 0;
  const hasName = Boolean(name);

  const hasValidLocalCutoffTime = Boolean(localCutoffTimeHour) && Boolean(localCutoffTimeMinute);

  const destinationsAreValid = destinations.every(destination => {
    const hasCountry = Boolean(destination.country);
    const postalCodesValid = destination.postalCodeRanges.every(postalCodeRange => {
      const hasStart = Boolean(postalCodeRange.start);
      const hasEnd = Boolean(postalCodeRange.end);
      return hasStart && hasEnd;
    });
    return hasCountry && postalCodesValid;
  });

  const displayNamesAreValid = areDisplayNamesValid(displayNames);

  return (
    hasName &&
    hasValidLocalCutoffTime &&
    destinationsAreValid &&
    validDeliveryDays &&
    allValuesNotNegative &&
    maxDaysGreaterThanMinDays &&
    maxOffsetGreaterThanMinOffset &&
    maxFallbackDaysGreaterThanMinDays &&
    bufferTimeNonNegative &&
    displayNamesAreValid
  );
};

const areDisplayNamesValid = (displayNames: DisplayNameState[]) =>
  isEmpty(displayNames) ||
  displayNames.every(
    displayName => !isEmpty(displayName.locale) && (!isEmpty(displayName.name) || !isEmpty(displayName.description)),
  );
