import { css } from '@emotion/css';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { isEmpty, uniq } from 'lodash';
import { DateTime } from 'luxon';
import React, { Reducer, useEffect, useReducer, useState } from 'react';

import { Alert, ReactTablev6 } from '@cimpress/react-components';
import { ReactTablev6Props } from '@cimpress/react-components/lib/ReactTablev6';

import useDeliveryGroupVersions from '../../hooks/view/useDeliveryGroupVersions';
import useDeliveryOptionVersions from '../../hooks/view/useDeliveryOptionVersions';
import useDeliveryTierVersions from '../../hooks/view/useDeliveryTierVersions';
import { DeliveryGroupState } from '../../reducers/deliverygroup/deliveryGroupConstants';
import { Version, populateDeliveryVersions } from '../../reducers/deliverygroup/deliveryGroupVersionsActions';
import {
  DeliveryGroupVersionState,
  initialDeliveryGroupVersions,
} from '../../reducers/deliverygroup/deliveryGroupVersionsConstants';
import deliveryGroupVersionsReducer from '../../reducers/deliverygroup/deliveryGroupVersionsReducer';
import {
  DeliveryOptionVersionState,
  initialDeliveryOptionVersions,
} from '../../reducers/deliverygroup/deliveryOptionVersionsConstants';
import deliveryOptionVersionsReducer from '../../reducers/deliverygroup/deliveryOptionVersionsReducer';
import {
  DeliveryTierVersionState,
  initialDeliveryTierVersions,
} from '../../reducers/deliverygroup/deliveryTierVersionsConstants';
import deliveryTierVersionsReducer from '../../reducers/deliverygroup/deliveryTierVersionsReducer';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import { sortInDescOrderByDate, groupVersions, VersionGroup, getDiffChanges, Diff } from '../../reducers/shared';
import {
  DiffType,
  isKeyThatRequiresCacheAccess,
  orchestrateChangeDescriptionValueDisplay,
} from '../../reducers/versionChangeMappings';
import { DeliveryGroupResponse, DeliveryGroupVersionResponse } from '../../types/deliveryGroup';
import { DeliveryTierResponse } from '../../types/deliveryTier';
import { AuditLogError } from './AuditLogError';
import { AuditLogFilters } from './AuditLogFilters';

export type Filters = { users?: string[]; dates?: string[]; versionTypes?: string[] };
type ErrorTypes = { GROUP: boolean; OPTION: boolean; TIER: boolean };

export enum ChangeMade {
  'group' = 'Delivery Group',
  'option' = 'Delivery Option',
  'tier' = 'Delivery Tier',
}

const tableCellStyles = css`
  .rt-th,
  .rt-td {
    width: 22% !important;
    padding: 10px !important;
  }

  .rt-th:nth-child(3),
  .rt-td:nth-child(3) {
    width: 15% !important;
  }

  .rt-th:last-child,
  .rt-td:last-child {
    width: 40% !important;
  }

  a {
    overflow-wrap: break-word;
  }
`;

const stackedParagraphStyle = css`
  ul + p {
    margin-top: 20px;
  }
`;

const getQueryCacheForKey = ({
  responseKey,
  queryClient,
  deliveryGroupId,
}: {
  responseKey: Record<string, any>;
  queryClient: QueryClient;
  deliveryGroupId: string | undefined;
}) => {
  if (!deliveryGroupId) {
    return;
  }

  if (responseKey['ecommerceDeliveryTierId']) {
    const cache = queryClient.getQueryData(['delivery-tiers', { id: deliveryGroupId }]) as DeliveryTierResponse[];

    const id = responseKey['ecommerceDeliveryTierId'];
    return cache.find(c => c.id === id);
  }

  if (responseKey['ecommerceDeliveryGroupId']) {
    return queryClient.getQueryData(['delivery-group', { id: deliveryGroupId }]) as DeliveryGroupResponse;
  }

  return;
};

const getDatesForFilters = (groupedVersions: VersionGroup[]) => {
  const previouslySeen: { [key: string]: boolean } = {};
  return groupedVersions.reduce((acc, d) => {
    const date = DateTime.fromMillis(d.date).toLocaleString(DateTime.DATE_FULL);

    if (previouslySeen[date]) {
      return acc;
    }

    previouslySeen[date] = true;

    acc.push(date);

    return acc;
  }, [] as string[]);
};

const columns = ({
  queryClient,
  deliveryGroupId,
}: {
  queryClient: QueryClient;
  deliveryGroupId: string | undefined;
}): ReactTablev6Props['columns'] => [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ original }: { original: VersionGroup }) => {
      const { date } = original;
      return DateTime.fromMillis(date).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    },
  },
  {
    Header: 'User',
    accessor: 'user',
    Cell: ({ original }: { original: VersionGroup }) => {
      return original.user;
    },
  },
  {
    Header: 'Resources Changed',
    accessor: 'versionType',
    sortable: false,
    Cell: ({ original }: { original: VersionGroup }) => {
      const { changesMade } = original;
      return (
        <ul style={{ listStyleType: 'none', marginLeft: '0', paddingLeft: '0', lineHeight: 1.286 }}>
          {changesMade.map(type => {
            return <li key={type}>{ChangeMade[type]}</li>;
          })}
        </ul>
      );
    },
  },
  {
    Header: 'Change Description',
    accessor: 'diffs',
    filterable: false,
    sortable: false,
    Cell: ({ original }: { original: VersionGroup }) => {
      const { diffs } = original;

      if (diffs.length === 0) {
        return 'No changes were made';
      }

      const getIntroText = (diff: Diff) => {
        const itemName = diff.versionType !== 'group' ? diff.nameOfItem : '';
        if (diff.isFirstOfType) {
          return (
            <p key={`${diff.id}-${diff.nameOfItem}-paragraph`} style={{ marginBottom: '10px' }}>
              {itemName} {ChangeMade[diff.versionType]} was created with the following values:
            </p>
          );
        } else {
          return (
            <p key={`${diff.id}-${diff.nameOfItem}-paragraph`} style={{ marginBottom: '10px' }}>
              The following changes were made to the {itemName} {ChangeMade[diff.versionType]}:
            </p>
          );
        }
      };

      return (
        <>
          {diffs.map(diff => {
            return (
              <React.Fragment key={`${diff.id}-fragment`}>
                {getIntroText(diff)}
                <ul key={`${diff.id}-fragment-${diff.nameOfItem}-ul`}>
                  {Object.entries<Record<DiffType, Record<string, any>>>(diff.changes).map(([key, value]) => {
                    if (!value || isEmpty(value)) {
                      return null;
                    }

                    return (
                      <>
                        {Object.entries<Record<DiffType, Record<string, any>>>(value).map(([subKey, subValue]) => {
                          /**
                           * For clarity, we show the name of the delivery group or delivery tier that a version references
                           * when delivery options and tiers are added/moved/deleted from groups and tiers instead of
                           * showing the user the ID of the target group or tier that changed. The name isn't included in
                           * the version, though, so we get those values from Tanstack Query cache to reduce API calls.
                           */
                          const needValueFromCache = isKeyThatRequiresCacheAccess(subKey);
                          const queryClientCache = needValueFromCache
                            ? getQueryCacheForKey({
                                responseKey: { [subKey]: subValue },
                                queryClient,
                                deliveryGroupId,
                              })
                            : undefined;

                          const label = orchestrateChangeDescriptionValueDisplay(
                            diff,
                            key as DiffType,
                            subKey,
                            subValue,
                            queryClientCache,
                          );
                          if (!label) {
                            return null;
                          }

                          if (label.link) {
                            return (
                              <li
                                key={`${diff.id}-fragment-${diff.nameOfItem}-ul-${subKey}-${label.key ?? 'link-item'}`}
                              >
                                {label.text}{' '}
                                <a href={label.link} target="_blank" rel="noreferrer">
                                  {label.link}
                                </a>
                              </li>
                            );
                          }

                          return <li key={`${diff.id}-fragment-${diff.nameOfItem}-ul-${subKey}-li`}>{label.text}</li>;
                        })}
                      </>
                    );
                  })}
                </ul>
              </React.Fragment>
            );
          })}
        </>
      );
    },
  },
];

const AuditLogPage = ({
  deliveryGroup,
  deliveryOptions,
  deliveryTiers,
}: {
  deliveryGroup: DeliveryGroupState;
  deliveryOptions: DeliveryOptionState[];
  deliveryTiers: DeliveryTierState[];
}) => {
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState<Filters>({});
  const [dateOptions, setDateOptions] = useState<string[]>([]);
  const [userOptions, setUserOptions] = useState<string[]>([]);
  const [versionTypeOptions, setVersionTypeOptions] = useState<string[]>([]);
  const [data, setData] = useState<VersionGroup[]>([]);
  const [errorTypes, setErrorTypes] = useState<ErrorTypes>({ GROUP: false, OPTION: false, TIER: false });
  const hasErrors = Object.values(errorTypes).some(value => value === true);

  const [deliveryGroupVersions, deliveryGroupVersionsDispatch] = useReducer<
    Reducer<DeliveryGroupVersionState, unknown>
  >(deliveryGroupVersionsReducer, initialDeliveryGroupVersions());

  const [deliveryOptionVersions, deliveryOptionVersionsDispatch] = useReducer<
    Reducer<DeliveryOptionVersionState, unknown>
  >(deliveryOptionVersionsReducer, initialDeliveryOptionVersions());

  const [deliveryTierVersions, deliveryTierVersionsDispatch] = useReducer<Reducer<DeliveryTierVersionState, unknown>>(
    deliveryTierVersionsReducer,
    initialDeliveryTierVersions(),
  );

  const { isFetching: isFetchingDeliveryGroupVersions } = useDeliveryGroupVersions({
    enabled: !!deliveryGroup.id,
    deliveryGroupId: deliveryGroup.id!,
    filters,
    onSuccess: (data: DeliveryGroupVersionResponse & { etag: string }) => {
      deliveryGroupVersionsDispatch(populateDeliveryVersions({ response: data }));
    },
    onError: (error: Error) => {
      if (!errorTypes['GROUP']) {
        setErrorTypes(errorTypes => ({ ...errorTypes, GROUP: true }));
      }
    },
  });

  const { queryResult: deliveryOptionResponses, isLoading: isLoadingDeliveryOptionVersions } =
    useDeliveryOptionVersions({
      deliveryGroup,
      deliveryOptions,
      filters,
    });

  const { queryResult: deliveryTierResponses, isLoading: isLoadingDeliveryTierVersions } = useDeliveryTierVersions({
    deliveryGroup,
    deliveryTiers,
    filters,
  });

  useEffect(() => {
    if (!deliveryOptionResponses || deliveryOptionResponses.errors.length > 0) {
      setErrorTypes(errorTypes => {
        if (!errorTypes['OPTION']) {
          return { ...errorTypes, OPTION: true };
        }

        return errorTypes;
      });
      return;
    }

    deliveryOptionVersionsDispatch(
      populateDeliveryVersions({
        response: deliveryOptionResponses.responses,
        currentVersionState: deliveryOptionVersions,
      }),
    );
  }, [deliveryOptionVersions, deliveryOptionResponses]);

  useEffect(() => {
    if (!deliveryTierResponses || deliveryTierResponses.errors.length > 0) {
      setErrorTypes(errorTypes => {
        if (!errorTypes['TIER']) {
          return { ...errorTypes, TIER: true };
        }
        return errorTypes;
      });
      return;
    }

    deliveryTierVersionsDispatch(
      populateDeliveryVersions({
        response: deliveryTierResponses.responses,
        currentVersionState: deliveryTierVersions,
      }),
    );
  }, [deliveryTierVersions, deliveryTierResponses]);

  useEffect(() => {
    if (isFetchingDeliveryGroupVersions || isLoadingDeliveryOptionVersions || isLoadingDeliveryTierVersions) {
      return;
    }

    const versions: Version[] = [
      ...deliveryGroupVersions.versions,
      ...deliveryOptionVersions.versions,
      ...deliveryTierVersions.versions,
    ];

    const groupedVersions = getDiffChanges(sortInDescOrderByDate(groupVersions(versions)));

    if (versions.length > 0) {
      if (isEmpty(userOptions)) {
        setUserOptions(uniq(versions.map(version => version.modifiedBy ?? version.createdBy)));
      }

      if (isEmpty(dateOptions)) {
        setDateOptions(getDatesForFilters(groupedVersions));
      }

      if (isEmpty(versionTypeOptions)) {
        setVersionTypeOptions(uniq(groupedVersions.map(d => d.diffs.map(diff => diff.versionType)).flat()).sort());
      }
    }
    setData(groupedVersions);
  }, [
    isFetchingDeliveryGroupVersions,
    isLoadingDeliveryOptionVersions,
    isLoadingDeliveryTierVersions,
    deliveryGroupVersions,
    deliveryOptionVersions,
    deliveryTierVersions,
    userOptions,
    dateOptions,
    versionTypeOptions,
  ]);

  if (hasErrors && !data.length) {
    return (
      <>
        <h2 style={{ marginBottom: '20px' }}>Audit Log</h2>
        <Alert title="Error" message={<AuditLogError noResults={!data.length} />} status="danger" dismissible={false} />
      </>
    );
  }

  const records = data?.slice(0, 50) ?? [];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ marginBottom: '20px' }}>Audit Log</h2>
        <AuditLogFilters
          filters={filters}
          setFilters={setFilters}
          userOptions={userOptions}
          dateOptions={dateOptions}
          versionTypeOptions={versionTypeOptions}
        />
      </div>
      {hasErrors && <Alert title="Error" message={<AuditLogError />} status="danger" dismissible={false} />}
      <ReactTablev6
        columns={columns({ queryClient, deliveryGroupId: deliveryGroup.id })}
        data={records}
        showPagination={false}
        pageSize={records.length}
        minRows={10}
        loading={isFetchingDeliveryGroupVersions || isLoadingDeliveryOptionVersions || isLoadingDeliveryTierVersions}
        loadingText="Loading Audit History"
        noDataText="No Audit History found for this Ecommerce Delivery Group"
        className={`${tableCellStyles} ${stackedParagraphStyle}`}
      />
    </>
  );
};

export default AuditLogPage;
