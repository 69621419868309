import { useQueryClient, useMutation } from '@tanstack/react-query';

import { deleteDeliveryGroup } from '../../services/deliveryGroups';

const useDeleteDeliveryGroup = ({
  deliveryGroupId,
  onSuccess,
  onError,
}: {
  deliveryGroupId: string;
  onError?: (error: Error) => void;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation(deleteDeliveryGroup, {
    onSuccess: () => {
      queryClient.removeQueries(['delivery-group', { id: deliveryGroupId }]);
      onSuccess && onSuccess();
    },
    onError,
  });
};

export default useDeleteDeliveryGroup;
