import { useQuery } from '@tanstack/react-query';

import { Filters } from '../../components/home/HomePage';
import { getDeliveryGroups } from '../../services/deliveryGroups';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';

const useDeliveryGroups = ({
  offset,
  limit,
  accountIds,
  creators,
  onError,
  onSuccess,
}: Filters & {
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryGroupResponse[]) => void;
}) => {
  return useQuery({
    queryKey: ['delivery-groups', { offset, limit, accountIds, creators }],
    queryFn: () => getDeliveryGroups({ offset, limit, accountIds, creators }),
    onError,
    onSuccess,
  });
};

export default useDeliveryGroups;
