import React from 'react';
import countryList from 'react-select-country-list';

import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { Button, Select, TextField } from '@cimpress/react-components';

import {
  setDestinationField,
  setPostalCodeRangeField,
  addPostalCodeRange,
  removePostalCodeRange,
} from '../../../reducers/deliveryoption/deliveryOptionActions';
import { DestinationState, PostalCodeRange } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../../types/shared';

const availableCountries: SelectField[] = countryList().getData();

const DestinationEditor = ({
  destination,
  dispatch,
  style,
  edoId,
}: {
  destination: DestinationState;
  dispatch: React.Dispatch<unknown>;
  style: any;
  edoId: string;
}) => {
  const destinationId = destination.id;
  const updatePostalCodeRangeStart =
    (postalCodeRange: PostalCodeRange) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPostalCodeRangeField({
          edoId,
          destinationId,
          postalCodeRangeId: postalCodeRange.id,
          field: 'start',
          value: event.target.value,
        }),
      );
    };

  const updatePostalCodeRangeEnd =
    (postalCodeRange: PostalCodeRange) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPostalCodeRangeField({
          edoId,
          destinationId,
          postalCodeRangeId: postalCodeRange.id,
          field: 'end',
          value: event.target.value,
        }),
      );
    };

  const updateRemovePostalCodeRange = (postalCodeRange: PostalCodeRange) => () => {
    dispatch(
      removePostalCodeRange({
        edoId,
        destinationId,
        postalCodeRangeId: postalCodeRange.id,
      }),
    );
  };

  const updateCountry = (selection?: SelectField | null) => {
    dispatch(
      setDestinationField({
        edoId,
        destinationId,
        field: 'country',
        value: selection?.value,
      }),
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      <Select
        label="Country"
        value={availableCountries.find(country => country.value === destination.country)}
        options={availableCountries}
        onChange={updateCountry}
      />
      {destination.postalCodeRanges.map(postalCodeRange => (
        <div key={postalCodeRange.id} style={{ display: 'flex' }}>
          <TextField
            style={{ width: '50%', marginRight: '10px' }}
            label="Postal Code Start"
            required
            value={postalCodeRange.start}
            onChange={updatePostalCodeRangeStart(postalCodeRange)}
          />
          <TextField
            style={{ width: '50%', marginRight: '10px' }}
            label="Postal Code End"
            required
            value={postalCodeRange.end}
            onChange={updatePostalCodeRangeEnd(postalCodeRange)}
          />
          <Button style={{ height: '50px' }} variant="link" onClick={updateRemovePostalCodeRange(postalCodeRange)}>
            <IconBin className="text-info" />
          </Button>
        </div>
      ))}
      <Button onClick={() => dispatch(addPostalCodeRange({ edoId, destinationId }))}>Add postal range</Button>
    </div>
  );
};

export default DestinationEditor;
