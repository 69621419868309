import { useQuery } from '@tanstack/react-query';

import { Filters } from '../../components/view/AuditLogPage';
import { getDeliveryGroupVersions } from '../../services/deliveryGroups';
import { DeliveryGroupVersionResponse } from '../../types/deliveryGroup';

const useDeliveryGroupVersions = ({
  deliveryGroupId,
  enabled,
  filters,
  onError,
  onSuccess,
}: {
  deliveryGroupId: string;
  enabled: boolean;
  filters: Filters;
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryGroupVersionResponse & { etag: string }) => void;
}) => {
  return useQuery(
    ['delivery-group-versions', { deliveryGroupId, ...filters }],
    () => getDeliveryGroupVersions({ deliveryGroupId, filters }),
    {
      enabled,
      onError,
      onSuccess,
    },
  );
};

export default useDeliveryGroupVersions;
