import { useMemo, useState } from 'react';

import TableFilter, { accordionFilter } from '../home/TableFilter';
import { ChangeMade, Filters } from './AuditLogPage';

export const AuditLogFilters = ({
  filters,
  setFilters,
  userOptions,
  dateOptions,
  versionTypeOptions,
}: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  userOptions: string[];
  dateOptions: string[];
  versionTypeOptions: string[];
}) => {
  const [users, setUsers] = useState<string[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [versionTypes, setVersionTypes] = useState<string[]>([]);

  const filteredUsers = useMemo(() => filters.users || [], [filters.users]);
  const filteredDates = useMemo(() => filters.dates || [], [filters.dates]);
  const filteredVersionTypes = useMemo(() => filters.versionTypes || [], [filters.versionTypes]);

  const dateAccordion = useMemo(
    () =>
      accordionFilter(
        'Date',
        filteredUsers,
        dates,
        dateOptions.map(date => ({ payload: date, label: date })),
        setDates,
      ),
    [filteredUsers, dates, dateOptions],
  );

  const userAccordion = useMemo(
    () =>
      accordionFilter(
        'Users',
        filteredDates,
        users,
        userOptions.map(user => ({ payload: user, label: user })),
        setUsers,
      ),
    [filteredDates, users, userOptions],
  );

  const versionTypeAccordion = useMemo(
    () =>
      accordionFilter(
        'Resources Changed',
        filteredVersionTypes,
        versionTypes,
        versionTypeOptions.map(versionType => ({
          payload: versionType,
          label: ChangeMade[versionType as keyof typeof ChangeMade],
        })),
        setVersionTypes,
      ),
    [filteredVersionTypes, versionTypes, versionTypeOptions],
  );

  const filterProps = useMemo(
    () => [
      {
        filterName: 'users',
        filteredValues: filteredUsers,
        setFilteredValues: setUsers,
        filterOptions: users,
      },
      {
        filterName: 'dates',
        filteredValues: filteredDates,
        setFilteredValues: setDates,
        filterOptions: dates,
      },
      {
        filterName: 'versionTypes',
        filteredValues: filteredVersionTypes,
        setFilteredValues: setVersionTypes,
        filterOptions: versionTypes,
      },
    ],
    [filteredUsers, users, filteredDates, dates, filteredVersionTypes, versionTypes],
  );

  return (
    <TableFilter filterProps={filterProps} setFilters={setFilters}>
      {dateAccordion}
      {userAccordion}
      {versionTypeAccordion}
    </TableFilter>
  );
};
