import { DeliveryOptionVersion } from '../../types/deliveryOption';

export type DeliveryOptionVersionState = {
  versions: DeliveryOptionVersion[];
};

export const initialDeliveryOptionVersions = (): DeliveryOptionVersionState => {
  return {
    versions: [],
  };
};
