import { useQuery } from '@tanstack/react-query';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useState } from 'react';

import { AccountInfo, getAccount, getAccounts } from '../services/accounts';

export const useAccount = ({
  url,
  onError,
  enabled = true,
}: {
  url: string;
  onError: (error: Error) => void;
  enabled: boolean;
}) => {
  return useQuery(['account', url], () => getAccount(url), {
    enabled: enabled && !!url,
    onError,
  });
};

export const useAccounts = ({ filter, onError }: { filter: boolean; onError?: (error: Error) => void }) => {
  const [accountsMap, setAccountsMap] = useState<Record<string, AccountInfo>>({});

  const getAccountNameById = useCallback(
    id => {
      const account = accountsMap[id] || {};
      return account.name || 'Unknown Account';
    },
    [accountsMap],
  );

  const accountsQuery = useQuery(['accounts', filter], () => getAccounts(filter), {
    retry: 3,
    onError,
  });

  const { data } = accountsQuery;
  useEffect(() => {
    setAccountsMap(keyBy(data, 'id'));
  }, [data]);

  // NOTE: This returns the accounts data, and also creates a map of accountId => accountName
  // and exposes a function that takes in a url and gives you the account name.
  return {
    ...accountsQuery,
    accountsMap,
    getAccountNameById,
  };
};
