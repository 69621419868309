import produce from 'immer';

import { DeliveryTierVersion } from '../../types/deliveryTier';
import { getVersionsFromResponses } from '../shared';
import { DeliveryVersionActionTypes } from './deliveryGroupVersionsActions';

const deliveryTierVersionsReducer = produce((draft, action) => {
  switch (action.type) {
    case DeliveryVersionActionTypes.ADD_VERSIONS: {
      const { response, currentVersionState } = action.payload;
      return getVersionsFromResponses<DeliveryTierVersion>(response, currentVersionState);
    }

    default: {
      return draft;
    }
  }
});

export default deliveryTierVersionsReducer;
