import { isBoolean, isEmpty, isNumber, isObject, isString } from 'lodash';

import { DeliveryGroupResponse } from '../types/deliveryGroup';
import { DeliveryTierResponse } from '../types/deliveryTier';
import { Diff, DiffKeys } from './shared';

export type DiffType = 'added' | 'deleted' | 'updated';

export enum ChangeMessages {
  'added' = 'Added',
  'deleted' = 'Deleted',
  'updated' = 'Updated',
}

/**
 * The JSON keys in the version responses don't 100% match
 * the related text in the UI. This object maps the JSON keys
 * to the UI text.
 */
const JSON_TO_UI_TEXT_MAPPINGS = {
  name: 'Name',
  mappings: 'Overrides',
  timeZone: 'Time Zone',
  consolidateCart:
    'When displaying possible delivery speeds to customer, only return those that are available for all items',
  defaultEcommerceDeliveryOptions: 'Default Delivery Options',
  productConsolidations: 'Consolidations',
  _links: {
    calendar: 'Country Calendar',
    deliveryConstraints: 'Delivery Constraints',
  },
  displayNames: 'Display Names',
  bufferTime: 'MCP Buffer Time',
  dateSelection: 'Date Selection',
  maxDays: 'Delivered in Max Business Days',
  minDays: 'Delivered in Min Business Days',
  maxOffset: 'Delivered in Fastest Possible + Max Business Days',
  minOffset: 'Delivered in Fastest Possible + Min Business Days',
  minFallbackDays: 'Fallback Days Minimum',
  maxFallbackDays: 'Fallback Days Maximum',
  localCutoffTimes: 'End of Business Day',
  destinations: 'Destinations',
  country: 'Country',
  postalCodeRanges: 'Postal Range',
  tags: 'Tags',
  fulfillmentCapabilities: 'Fulfillment Capabilities',
  ignoreInTransitInventory:
    'In-Transit Inventory Consideration - Exclude in-transit inventory availability when calculating delivery dates',
  deliveryConstraints: 'Delivery Constraints',
};

/**
 * We only show changes in the change description related to keys
 * that the user directly changed (as opposed to keys with values that
 * are automatically updated by the system). These keys are used to
 * filter data out of the change description.
 */
const KEYS_TO_HIDE_FROM_CHANGE_DESCRIPTION = [
  'createdAt',
  'createdBy',
  'modifiedAt',
  'modifiedBy',
  'account',
  'version',
  'versionType',
  'deleted',
  'id',
];

/**
 * We only get ID values for these keys. We can get more details on the data they represent
 * by accessing the Tanstack Query cache.
 */
const KEYS_THAT_REQUIRE_CACHE_ACCESS = ['ecommerceDeliveryTierId', 'ecommerceDeliveryGroupId'];

/**
 * Converts camel case strings to sentence case and inserts a space between words (based on
 * capital letters)
 *
 * Examples:
 * updated -> Updated
 * postalCodeRanges -> Postal Code Ranges
 */
export const camelCaseToSentenceCase = (value: string): string => {
  const withCapFirstLetter = `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
  return withCapFirstLetter.match(/[A-Z][a-z]+/g)?.join(' ') ?? withCapFirstLetter;
};

// Gets the key/value pair most deeply nested within an object.
export const getLastKeyValuePairFromNestedObj = (
  obj: Record<string, any>,
): { key: string | undefined; value: any[] | Record<string, any> } => {
  let result: Record<string, any> = obj;
  let lastKey = undefined;
  while (typeof result === 'object' && !Array.isArray(result)) {
    const keys = Object.keys(result);
    if (keys.length === 0) break;

    lastKey = keys[keys.length - 1];
    result = result[lastKey];
  }
  return {
    key: lastKey,
    value: result,
  };
};

// Checks if a key/value pair should be excluded from the UI
// based on the key
export const excludeDiffValuesFromUI = (key: string) => {
  if (KEYS_TO_HIDE_FROM_CHANGE_DESCRIPTION.includes(key)) {
    return true;
  }

  return false;
};

// Checks if values are needed from Tanstack Query cache based
// on the key
export const isKeyThatRequiresCacheAccess = (key: string) => {
  if (KEYS_THAT_REQUIRE_CACHE_ACCESS.includes(key)) {
    return true;
  }

  return false;
};

/**
 * When we add/move/delete delivery options and delivery tiers within a delivery group
 * or add/move/delete a delivery option within a delivery tier, we just get the ID
 * value for the target group or tier. This function uses Tanstack Query's cache to
 * get the name of the target group or tier so that we can show that value in the UI
 * to better help users understand what changed.
 */
const getTextWithCacheValue = (
  diff: Record<string, any>,
  key: string,
  changeType: DiffKeys,
  cacheData: DeliveryGroupResponse | DeliveryTierResponse | undefined,
) => {
  const { versionType, nameOfItem } = diff;

  const versionTypeText = versionType === 'option' ? 'Delivery Option' : 'Delivery Tier';
  const verb = camelCaseToSentenceCase(changeType); // Added, Updated, Deleted
  const nameOfChangedItem = nameOfItem ? `${nameOfItem} ${versionTypeText}` : `a ${versionTypeText}`;
  const nameOfTargetItem = cacheData?.name ?? diff.changes[changeType][key];

  if (versionType === 'group') {
    return;
  }

  /**
   * The ecommerceDeliveryTierId key is added when an option is added/moved to/deleted
   * from a delivery tier. The language below reflects these actions.
   */
  if (key === 'ecommerceDeliveryTierId') {
    if (changeType === 'deleted') {
      return { text: `${verb} the ${nameOfTargetItem} Delivery Tier` };
    }

    if (changeType === 'added') {
      return { text: `${verb} ${nameOfChangedItem} to ${nameOfTargetItem} Delivery Tier` };
    }

    if (changeType === 'updated') {
      return {
        text: `Moved ${nameOfChangedItem} to ${nameOfTargetItem} Delivery Tier`,
      };
    }
  }

  /**
   * The ecommerceDeliveryGroupId key is added when a delivery tier or delivery option is
   * added or moved to a delivery group. Currently, the deleted statement shouldn't
   * be hit because we don't show deleted versions, but that will change in the future.
   * The language below reflects these cases.
   */
  if (key === 'ecommerceDeliveryGroupId') {
    if (changeType === 'deleted') {
      return {
        text: `${verb} the ${nameOfChangedItem} from the Delivery Group`,
      };
    }

    if (changeType === 'added') {
      return {
        text: `${verb} ${nameOfChangedItem} to the Delivery Group`,
      };
    }

    if (changeType === 'updated') {
      return {
        text: `Moved ${nameOfChangedItem} to the Delivery Group`,
      };
    }
  }

  return;
};

const isPrimitiveValue = (value: any) => {
  if (isString(value) || isNumber(value) || isBoolean(value)) {
    return true;
  }

  return false;
};

/**
 * Uses the diff and diff type (added, updated, deleted) with keys and values from the diff to determine what
 * text to show in the UI to summarize the changes between two versions.
 */
export const orchestrateChangeDescriptionValueDisplay = (
  diff: Diff,
  diffType: DiffType,
  key: string,
  value: any,
  queryClientCache: DeliveryGroupResponse | DeliveryTierResponse | undefined,
): undefined | { text: string; link?: string; key?: string } => {
  const verb = camelCaseToSentenceCase(diffType); // Added, Updated, Deleted

  /**
   * There are some values that update automatically on every change (like the version number and related links)
   * as well as values that we show in other ways in the UI (like the createdAt/modifiedAt date), so we can exclude
   * those from the summary.
   */
  if (excludeDiffValuesFromUI(key)) {
    return;
  }

  if (isKeyThatRequiresCacheAccess(key)) {
    return getTextWithCacheValue(diff, key, diffType, queryClientCache);
  }

  /**
   * When something has been deleted, we try to get the deepest key in the value object so that with
   * deeply nested structures, we can provide the best context. As an example, if a postal code range is
   * deleted, this is the shape of the value object:
   *
   * { destinations: { 0: { postalCodeRanges: {} } } }
   *
   * Instead of showing that destinations was deleted, which may not actually be true (there could be multiple
   * postal code ranges), we get the deepest key (postalCodeRanges) and show that postal code ranges were deleted.
   *
   * If the value isn't an object, we just use the key that was passed as a parameter.
   */
  if (diffType === 'deleted') {
    const nestedKey = getLastKeyValuePairFromNestedObj(value).key ?? key;
    const uiKey =
      JSON_TO_UI_TEXT_MAPPINGS[nestedKey as keyof typeof JSON_TO_UI_TEXT_MAPPINGS] ??
      camelCaseToSentenceCase(nestedKey);

    return {
      text: `${verb} ${uiKey}`,
    };
  }

  /**
   * When we create versions, they'll have empty array values for some of the keys by default (like
   * mappings and display names). We don't want to show that these were changed when they actually weren't.
   * If there was a value for a given key and it was removed (i.e., the array had a value and now it doesn't),
   * that'll be reflected as a deleted diff with this value: { '0': undefined }.
   */
  if (Array.isArray(value) && isEmpty(value)) {
    return;
  }

  if (isObject(value)) {
    /**
     * The self and version links will be on every diff and will always increase by one.
     * It doesn't seem necessary to highlight this for the user so we omit it to reduce noise.
     *
     * For links that change based on user actions, like calendar and delivery constraint links,
     * we'll return a link to the  JSON for the UI
     */
    if (key === '_links') {
      if (value['calendar']) {
        return {
          text: `${JSON_TO_UI_TEXT_MAPPINGS[key]['calendar']} was set to`,
          link: value['calendar'].href,
          key: 'calendar',
        };
      }

      if (value['deliveryConstraints']) {
        return {
          text: `${JSON_TO_UI_TEXT_MAPPINGS[key]['deliveryConstraints']} were set to`,
          link: value['deliveryConstraints'].href,
          key: 'deliveryConstraints',
        };
      }
      return;
    }

    const values = Object.values(value);

    // If we have a value that's a single empty array, then we don't include it
    // This happens for default key/value pairs that are set on versions
    if (values.length === 1 && Array.isArray(values[0]) && values[0].length === 0) {
      return;
    } else {
      // For more complicated changes that include objects or arrays of values,
      // we'll just state what changed at the top level; the changes will be available
      // through the diff viewer
      return {
        text: `${verb} ${
          JSON_TO_UI_TEXT_MAPPINGS[key as keyof typeof JSON_TO_UI_TEXT_MAPPINGS] ?? camelCaseToSentenceCase(key)
        }`,
      };
    }
  }

  return {
    text: `${verb} ${
      JSON_TO_UI_TEXT_MAPPINGS[key as keyof typeof JSON_TO_UI_TEXT_MAPPINGS] ?? camelCaseToSentenceCase(key)
    }${isPrimitiveValue(value) ? ': ' + value : ''}`,
  };
};

export const removeEmptyObjects = (obj: Record<string, any>): Record<string, any> => {
  const entries = Object.entries(obj);

  entries.forEach(([key, value]) => {
    if (isEmpty(value)) {
      delete obj[key];
    } else {
      removeEmptyObjects(value as Record<string, any>);
    }
  });

  return obj;
};

/**
 * The diff library returns the same key in
 * the deleted and updated objects when something is deleted
 * from the configuration. So, if you delete display names,
 * for example, you'd see this:
 *
 * {
 *    added: {},
 *    deleted: { displayNames: { 0: undefined } },
 *    updated: { displayNames: {} }
 * }
 *
 * So, we check for both conditions so that we know when to
 * show that the property was actually deleted (and don't also show
 * that it was updated unless it actually was)
 */
export const wasDeleted = (diff: Record<DiffKeys, any>, key: string): boolean => {
  const deletedValue = diff.deleted[key];
  const updatedValue = diff.updated[key];

  if (!deletedValue || !updatedValue) {
    return false;
  }

  if (!deletedValue['0'] && isEmpty(updatedValue)) {
    return true;
  }

  if (
    !getLastKeyValuePairFromNestedObj(deletedValue).value &&
    isEmpty(getLastKeyValuePairFromNestedObj(updatedValue).value)
  ) {
    return true;
  }

  return false;
};
