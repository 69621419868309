import isEmpty from 'lodash/isEmpty';
import { stringify } from 'querystring';

import { Filters } from '../components/home/HomePage';
import { Filters as AuditLogFilters } from '../components/view/AuditLogPage';
import {
  DeliveryGroupResponse,
  DeliveryGroupCollection,
  DeliveryGroupVersionResponse,
  DeliveryGroupVersion,
} from '../types/deliveryGroup';
import { Account, getAccountGroupId } from './accounts';
import { fetchWithAuth } from './fetchWithAuth';
import { versionFilterGate } from './shared';

const endpointUrl = process.env.REACT_APP_ECOMMERCE_DELIVERY_OPTIONS_URL as string;

type FilterQueries = {
  offset: number;
  limit: number;
  accountId?: string[];
  createdBy?: string[];
};

export const getDeliveryGroup = async ({
  id,
}: {
  id: string;
}): Promise<DeliveryGroupResponse & { etag: string; accountName: string }> => {
  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'GET',
    route: `v1/ecommerceDeliveryGroups/${id}`,
    giveSimpleResponse: false,
  });

  const deliveryGroupResponse: DeliveryGroupResponse = result.body;
  const etag = result.response.headers.get('etag');

  const account: Account = await fetchWithAuth({
    endpointUrl: deliveryGroupResponse.account._links.self.href,
  });
  return { ...deliveryGroupResponse, etag, accountName: account.name };
};

export const getDeliveryGroups = async ({
  offset,
  limit,
  accountIds,
  creators,
}: Filters): Promise<DeliveryGroupResponse[]> => {
  const queryString: FilterQueries = {
    offset,
    limit,
  };

  if (!isEmpty(accountIds)) {
    queryString.accountId = accountIds;
  }

  if (!isEmpty(creators)) {
    queryString.createdBy = creators;
  }

  const deliveryGroups: DeliveryGroupResponse[] = [];
  let next: string | undefined;

  do {
    const response: DeliveryGroupCollection = next
      ? await fetchWithAuth({ endpointUrl: next })
      : await fetchWithAuth({
          endpointUrl,
          method: 'GET',
          route: `v1/ecommerceDeliveryGroups?sort=createdAt:desc&${stringify(queryString)}`,
        });

    deliveryGroups.push(...response._embedded.ecommerceDeliveryGroups);
    next = response._links?.next?.href;
  } while (next);

  return deliveryGroups;
};

export const addEdoToGroup = (essentialGroupId: number, deliveryGroupId: string) => {
  return fetchWithAuth({
    endpointUrl,
    method: 'PATCH',
    route: `v1/ecommerceDeliveryGroups/${deliveryGroupId}/groups`,
    body: { groupId: essentialGroupId.toString() },
    giveSimpleResponse: false,
  });
};

export const createDeliveryGroup = async ({
  name,
  accountId,
}: {
  name: string;
  accountId: string;
}): Promise<DeliveryGroupResponse & { etag: string }> => {
  const essentialGroupId = await getAccountGroupId(accountId);
  const request = {
    name: name.trim(),
    account: {
      id: accountId,
    },
  };

  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'POST',
    route: 'v1/ecommerceDeliveryGroups',
    body: request,
    giveSimpleResponse: false,
  });

  const updatedEtag: string = result.response.headers.get('etag');
  const deliveryGroup: DeliveryGroupResponse = result.body;

  await addEdoToGroup(essentialGroupId, deliveryGroup.id);
  return { ...deliveryGroup, etag: updatedEtag };
};

export const deleteDeliveryGroup = async ({ id, etag }: { id: string; etag: string }) => {
  return fetchWithAuth({
    endpointUrl,
    method: 'DELETE',
    route: `v1/ecommerceDeliveryGroups/${id}`,
    additionalHeaders: { 'If-Match': etag },
  });
};

export const cloneDeliveryGroup = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}): Promise<DeliveryGroupResponse> => {
  const request = { name };

  return fetchWithAuth<DeliveryGroupResponse>({
    endpointUrl,
    method: 'POST',
    route: `/v1/ecommerceDeliveryGroups/${id}:clone`,
    body: request,
  });
};

export const getDeliveryGroupVersions = async ({
  deliveryGroupId,
  filters,
}: {
  deliveryGroupId: string;
  filters: AuditLogFilters;
}): Promise<DeliveryGroupVersionResponse & { etag?: string }> => {
  // Currently only requests most recent 50 versions
  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'GET',
    route: `/v1/ecommerceDeliveryGroups/${deliveryGroupId}/versions?offset=0&limit=50&sort=createdAt%3Adesc`,
    giveSimpleResponse: false,
  });

  const deliveryGroupVersionsResponse: DeliveryGroupVersionResponse = result.body;

  deliveryGroupVersionsResponse._embedded.item = result.body._embedded.item.filter((item: DeliveryGroupVersion) => {
    item.versionType = 'group';
    return versionFilterGate(filters, item);
  });

  const etag = result.response.headers.get('etag');

  return { ...deliveryGroupVersionsResponse, etag };
};
