import { useMutation, useQueryClient } from '@tanstack/react-query';

import { batchUpdate } from '../../services/batchUpdate';

const useBatchUpdate = ({
  deliveryGroupId,
  onSuccess,
  onError,
}: {
  deliveryGroupId: string;
  onSuccess?: (data: any, variables: any) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(batchUpdate, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(['delivery-group', { id: deliveryGroupId }]);
      await queryClient.invalidateQueries(['delivery-options', { id: deliveryGroupId }]);
      await queryClient.invalidateQueries(['delivery-tiers', { id: deliveryGroupId }]);
      onSuccess && onSuccess(data, variables);
    },
    onError,
  });
};

export default useBatchUpdate;
