import { DeliveryGroupVersion } from '../../types/deliveryGroup';

export type DeliveryGroupVersionState = {
  versions: DeliveryGroupVersion[];
};

export const initialDeliveryGroupVersions = (): DeliveryGroupVersionState => {
  return {
    versions: [],
  };
};
