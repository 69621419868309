import { fetchWithAuth } from './fetchWithAuth';

const deliveryConstraintsUrl = process.env.REACT_APP_DELIVERY_CONSTRAINTS_URL as string;

type DeliveryConstraintsRequest = {
  dispatchCountry?: string;
  dispatchPostalCode?: string;
  preferredCarrierServices?: string[];
  carrierServices?: string[];
  carrierServiceCapabilities?: string[];
};

export type DeliveryConstraintsResponse = {
  deliveryConstraintsId: string;
  _links: {
    self: {
      href: string;
    };
  };
} & DeliveryConstraintsRequest;

export const createDeliveryConstraints = async ({
  dispatchCountry,
  dispatchPostalCode,
  preferredCarrierServices,
  carrierServices,
  carrierServiceCapabilities,
}: DeliveryConstraintsRequest) => {
  return fetchWithAuth<DeliveryConstraintsResponse>({
    endpointUrl: deliveryConstraintsUrl,
    route: 'v1/deliveryconstraints',
    method: 'POST',
    body: {
      dispatchCountry,
      dispatchPostalCode,
      preferredCarrierServices,
      carrierServices,
      carrierServiceCapabilities,
    },
  });
};
