import produce from 'immer';

import * as displayNameActions from '../deliveryoption/deliveryOptionActions';
import { initialDisplayName } from '../deliveryoption/deliveryOptionConstants';
import * as actions from './deliveryTierActions';
import { initialDeliveryTier } from './deliveryTierConstants';

const getMatchingIndex = (arr: { id: string }[], id: string) => arr.findIndex(element => element.id === id);

const deliveryTierReducer = produce((draft, action) => {
  switch (action.type) {
    case actions.ADD_DELIVERY_TIER: {
      draft.push(initialDeliveryTier());
      break;
    }

    case actions.REMOVE_DELIVERY_TIER: {
      const { tierId } = action.payload;
      const index = getMatchingIndex(draft, tierId);
      const tierToRemove = draft[index];
      tierToRemove.isNewTier ? draft.splice(index, 1) : (tierToRemove.isDeleted = true);
      break;
    }

    case actions.VALIDATE_DELIVERY_TIER: {
      const { tierId, isValid } = action.payload;
      const index = getMatchingIndex(draft, tierId);
      draft[index].isValid = isValid;
      break;
    }

    case actions.SET_FIELD: {
      const { tierId, field, value } = action.payload;
      const index = getMatchingIndex(draft, tierId);
      draft[index][field] = value;
      draft[index].hasUnsavedWork = true;
      break;
    }

    case displayNameActions.ADD_DISPLAY_NAME: {
      const { resourceId } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      draft[edoIndex].displayNames.push(initialDisplayName());
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case displayNameActions.REMOVE_DISPLAY_NAME: {
      const { resourceId, displayNameId } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      const displayNameIndex = getMatchingIndex(draft[edoIndex].displayNames, displayNameId);
      draft[edoIndex].displayNames.splice(displayNameIndex, 1);
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case displayNameActions.SET_DISPLAY_NAME_FIELD: {
      const { resourceId, displayNameId, field, value } = action.payload;
      const edoIndex = getMatchingIndex(draft, resourceId);
      const displayNameIndex = getMatchingIndex(draft[edoIndex].displayNames, displayNameId);
      draft[edoIndex].displayNames[displayNameIndex][field] = value;
      draft[edoIndex].hasUnsavedWork = true;
      break;
    }

    case actions.POPULATE_DELIVERY_TIERS: {
      return action.payload;
    }

    default:
      return draft;
  }
});

export default deliveryTierReducer;
