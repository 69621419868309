import { css } from '@emotion/css';
import ct from 'countries-and-timezones';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import countryList from 'react-select-country-list';

import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { Toggle, Button, Select, Checkbox, NavTab, NavTabItem, TextField, colors } from '@cimpress/react-components';

import { setField } from '../../reducers/deliverygroup/deliveryGroupActions';
import { DeliveryGroupState } from '../../reducers/deliverygroup/deliveryGroupConstants';
import { addDeliveryOption } from '../../reducers/deliveryoption/deliveryOptionActions';
import { DeliveryOptionState } from '../../reducers/deliveryoption/deliveryOptionConstants';
import { addDeliveryTier } from '../../reducers/deliverytier/deliveryTierActions';
import { DeliveryTierState } from '../../reducers/deliverytier/deliveryTierConstants';
import { SelectField } from '../../types/shared';
import DeliveryOptionEditor, { fieldCss } from '../create/options/DeliveryOptionEditor';
import ConsolidationsEditor from '../create/rules/ConsolidationsEditor';
import DndMappingEditor from '../create/rules/DndMappingEditor';
import DeliveryTierEditor from '../create/tiers/DeliveryTierEditor';
import InformationHeader from '../shared/InformationHeader';
import LineBreak from '../shared/LineBreak';
import CategorizedDeliveryOptions from './CategorizedDeliveryOptions';

const fieldDisplayCss = css`
  width: 100%;
  max-width: 500px;
`;

const groupDisplayCss = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
`;

export const availableCountryCalendars: SelectField[] = countryList()
  .getData()
  .map((country: { value: string; label: string }) => ({
    value: `${process.env.REACT_APP_CUSTOM_CALENDARS_URL}/v1/calendars/default-${country.value}`,
    label: country.label,
  }));

export type TabSelection = 'OPTIONS' | 'RULES' | 'TIERS';

const EditPage = ({
  displayGroupName = true,
  areRulesValid,
  deliveryGroup,
  deliveryGroupDispatch,
  deliveryOptions,
  deliveryOptionsDispatch,
  deliveryTiers,
  deliveryTiersDispatch,
}: {
  displayGroupName?: boolean;
  areRulesValid: boolean;
  deliveryGroup: DeliveryGroupState;
  deliveryGroupDispatch: React.Dispatch<unknown>;
  deliveryOptions: DeliveryOptionState[];
  deliveryOptionsDispatch: React.Dispatch<unknown>;
  deliveryTiers: DeliveryTierState[];
  deliveryTiersDispatch: React.Dispatch<unknown>;
}) => {
  const usingDeliveryTiers = deliveryTiers.length > 0;
  const initialSelection = usingDeliveryTiers ? 'TIERS' : 'OPTIONS';

  const [selectedTab, setSelectedTab] = useState<TabSelection>(initialSelection);
  const [enableTiers, setEnableTiers] = useState<boolean>(usingDeliveryTiers);

  const availableEdos = deliveryOptions.map((option: { id: string; name?: string }) => ({
    label: option?.name ?? `Unnamed EDO`,
    value: option.id,
  }));

  const selectedDefaultEdos = deliveryGroup.defaultEdoIds.map((edoId: string) => {
    const defaultEdo = find(deliveryOptions, edo => edo.id === edoId);
    return { label: defaultEdo?.name ?? 'N/A', value: edoId };
  });

  const onDefaultEdoSelect = (selectedEdos: SelectField[]) =>
    deliveryGroupDispatch(
      setField({
        field: 'defaultEdoIds',
        value: selectedEdos?.map(selectedEdo => selectedEdo.value) ?? [],
      }),
    );

  const isCustomCalendar =
    deliveryGroup.calendarUrl &&
    !availableCountryCalendars.some(calendarSelect => calendarSelect.value === deliveryGroup.calendarUrl);

  const getSelectedTabData = () => {
    switch (selectedTab) {
      case 'TIERS':
        return (
          <div style={{ marginLeft: 10 }}>
            <p>
              This is an <b>optional</b> feature that allows you to group different delivery options into a category and
              offer them as a single shipping speed on the website.
            </p>
            <p>If even one delivery tier is set up, all delivery options must be categorized into a delivery tier.</p>
            {/*// TODO: add link to official tier documentation*/}
            {deliveryTiers.map(tier => (
              <DeliveryTierEditor key={tier.id} deliveryTier={tier} dispatch={deliveryTiersDispatch} tierId={tier.id} />
            ))}
            <Button onClick={() => deliveryTiersDispatch(addDeliveryTier())}>Add Delivery Tier</Button>
          </div>
        );
      case 'OPTIONS':
        return (
          <div style={{ marginLeft: 10 }}>
            {usingDeliveryTiers ? (
              <CategorizedDeliveryOptions
                deliveryOptions={deliveryOptions}
                deliveryOptionsDispatch={deliveryOptionsDispatch}
                deliveryTiers={deliveryTiers}
              />
            ) : (
              <>
                {deliveryOptions.map(option => (
                  <DeliveryOptionEditor
                    key={option.id}
                    deliveryOption={option}
                    dispatch={deliveryOptionsDispatch}
                    edoId={option.id}
                  />
                ))}
                <Button onClick={() => deliveryOptionsDispatch(addDeliveryOption())}>Add Delivery Option</Button>
              </>
            )}
          </div>
        );
      case 'RULES':
        return (
          <div style={{ marginLeft: 20 }}>
            <InformationHeader
              header="Defaults"
              contents="Configure default delivery options that will be applied to all items"
            />
            <Select
              className={fieldCss}
              required
              // @ts-ignore
              isMulti
              label="Default Delivery Options"
              // @ts-ignore
              value={selectedDefaultEdos}
              options={availableEdos}
              // @ts-ignore
              onChange={onDefaultEdoSelect}
            />
            <InformationHeader
              header="Overrides"
              contents="Configure overrides that apply delivery options to specific products"
            />
            <DndMappingEditor
              mappings={deliveryGroup.mappings}
              deliveryOptions={deliveryOptions}
              dispatch={deliveryGroupDispatch}
            />
            <InformationHeader
              header="Consolidations"
              contents="Consolidate products to offer the same delivery options"
            />
            <ConsolidationsEditor
              consolidations={deliveryGroup.productConsolidations}
              dispatch={deliveryGroupDispatch}
            />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div>
      <div style={{ marginLeft: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h2 style={{ marginBottom: 20 }}>Delivery Group Settings</h2>
          <div style={{ margin: 30 }}>
            Enable Tiers{'  '}
            <Toggle
              size={'sm'}
              on={enableTiers}
              disabled={usingDeliveryTiers}
              onClick={() => {
                enableTiers && setSelectedTab('OPTIONS');
                setEnableTiers(!enableTiers);
              }}
            />
          </div>
        </div>
        <div className={groupDisplayCss}>
          {displayGroupName && (
            <TextField
              className={fieldDisplayCss}
              label="Delivery Group Name"
              required
              value={deliveryGroup.name}
              onChange={e => deliveryGroupDispatch(setField({ field: 'name', value: e.target.value }))}
            />
          )}
          <TextField
            className={fieldDisplayCss}
            label="Related Account or Business"
            disabled
            value={deliveryGroup?.account?.label}
            onChange={e => e}
          />
          <Select
            label="Time Zone"
            value={deliveryGroup.timeZone}
            options={sortBy(ct.getAllTimezones(), 'name').map(tz => ({ label: tz.name, value: tz.name }))}
            onChange={(timeZone?: SelectField | null) =>
              deliveryGroupDispatch(setField({ field: 'timeZone', value: timeZone }))
            }
            required
          />
          {!isCustomCalendar && (
            <Select
              label="Country Calendar"
              value={find(availableCountryCalendars, calendar => calendar.value === deliveryGroup.calendarUrl)}
              options={availableCountryCalendars}
              onChange={(calendar?: SelectField | null) =>
                deliveryGroupDispatch(setField({ field: 'calendarUrl', value: calendar?.value }))
              }
              required
            />
          )}
        </div>
        {isCustomCalendar && (
          <p>
            This Ecommerce Delivery Group has a custom business day calendar attached. If you would like to remove or
            edit the calendar please reach out to <a href="mailto:dispatchsquad@cimpress.com">Dispatch Squad</a>.
          </p>
        )}
        <Checkbox
          label="When displaying possible delivery speeds to customer, only return those that are available for all items"
          checked={deliveryGroup.consolidateCart}
          onChange={() =>
            deliveryGroupDispatch(setField({ field: 'consolidateCart', value: !deliveryGroup.consolidateCart }))
          }
        />
      </div>
      <LineBreak />
      <NavTab>
        {enableTiers && (
          <NavTabItem active={selectedTab === 'TIERS'}>
            <button onClick={() => setSelectedTab('TIERS')}>
              <div style={{ display: 'flex' }}>
                <h2>Delivery Tiers</h2>
                {deliveryTiers.some(tierState => !tierState.isValid) && (
                  <IconAlertTriangle
                    size="lg"
                    weight="fill"
                    color={colors.danger.base}
                    style={{ margin: '25 0 0 10' }}
                  />
                )}
              </div>
            </button>
          </NavTabItem>
        )}
        <NavTabItem active={selectedTab === 'OPTIONS'}>
          <button onClick={() => setSelectedTab('OPTIONS')}>
            <div style={{ display: 'flex' }}>
              <h2>Delivery Options</h2>
              {(deliveryOptions.length === 0 || deliveryOptions.some(edoState => !edoState.isValid)) && (
                <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ margin: '25 0 0 10' }} />
              )}
            </div>
          </button>
        </NavTabItem>
        <NavTabItem active={selectedTab === 'RULES'}>
          <button onClick={() => setSelectedTab('RULES')}>
            <div style={{ display: 'flex' }}>
              <h2>Product Rules</h2>
              {!areRulesValid && (
                <IconAlertTriangle size="lg" weight="fill" color={colors.danger.base} style={{ margin: '25 0 0 10' }} />
              )}
            </div>
          </button>
        </NavTabItem>
      </NavTab>
      {getSelectedTabData()}
    </div>
  );
};

export default EditPage;
