import orderBy from 'lodash/orderBy';

import { Filters } from '../components/view/AuditLogPage';
import { DeliveryTierResponse, DeliveryTierVersionResponse, DeliveryTiersResponse } from '../types/deliveryTier';
import { fetchWithAuth } from './fetchWithAuth';
import { versionFilterGate } from './shared';

const endpointUrl = process.env.REACT_APP_ECOMMERCE_DELIVERY_OPTIONS_URL as string;

export const getDeliveryTiers = async ({
  deliveryGroupId,
}: {
  deliveryGroupId: string;
}): Promise<DeliveryTierResponse[]> => {
  const deliveryTiersResponse: DeliveryTierResponse[] = [];
  let next: string | undefined = `${endpointUrl}/v1/ecommerceDeliveryGroups/${deliveryGroupId}/tiers`;

  do {
    const response: DeliveryTiersResponse = await fetchWithAuth({
      endpointUrl: next,
    });

    const deliveryTierResponses = response._embedded.tiers;
    deliveryTiersResponse.push(...deliveryTierResponses);
    next = response._links.next?.href;
  } while (next);
  return orderBy(deliveryTiersResponse, ['name'], ['asc']);
};

export const getDeliveryTierVersions = async ({
  deliveryGroupId,
  deliveryTierId,
  filters,
}: {
  deliveryGroupId: string;
  deliveryTierId: string;
  filters: Filters;
}): Promise<DeliveryTierVersionResponse & { etag: string }> => {
  // Currently only requests most recent 50 versions
  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'GET',
    route: `/v1/ecommerceDeliveryGroups/${deliveryGroupId}/tiers/${deliveryTierId}/versions?offset=0&limit=50&sort=createdAt%3Adesc`,
    giveSimpleResponse: false,
  });

  const deliveryTierVersionsResponse = result.body as DeliveryTierVersionResponse;
  deliveryTierVersionsResponse._embedded.item = deliveryTierVersionsResponse._embedded.item.filter(item => {
    item.versionType = 'tier';
    return versionFilterGate(filters, item);
  });

  const etag = result.response.headers.get('etag');

  return { ...deliveryTierVersionsResponse, etag };
};
