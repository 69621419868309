import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const Loading = ({ fillSpace = true }: { fillSpace?: boolean }) => {
  if (fillSpace) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <Spinner />
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner className="text-center" />;
  }
};

export default Loading;
