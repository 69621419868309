import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createDeliveryGroup } from '../../services/deliveryGroups';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';

const useCreateDeliveryGroup = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    data: DeliveryGroupResponse & { etag: string },
    variables: {
      name?: string;
      accountId?: string;
    },
  ) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(createDeliveryGroup, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['delivery-group', { id: data.id }], data);
      onSuccess && onSuccess(data, variables);
    },
    onError,
  });
};

export default useCreateDeliveryGroup;
