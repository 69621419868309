import React from 'react';

import { Select } from '@cimpress/react-components';

import { expectationLabels } from '../../../reducers/deliveryoption/deliveryOptionConstants';
import { SelectField } from '../../../types/shared';

const options: SelectField[] = Object.entries(expectationLabels).map(([value, label]) => ({ label, value }));

const FulfillmentCapabilitiesSelect = ({
  className,
  value,
  onChange,
}: {
  value: string[];
  onChange: (data: SelectField[]) => void;
  className: any;
}) => {
  const selectedOptions = options.filter(option => value.includes(option.value));
  return (
    <Select
      className={className}
      // @ts-ignore
      isMulti
      label="Fulfillment Capabilities"
      // @ts-ignore
      value={selectedOptions}
      options={options}
      // @ts-ignore
      onChange={onChange}
    />
  );
};

export default FulfillmentCapabilitiesSelect;
