import P from 'bluebird';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import toNumber from 'lodash/toNumber';

import { DeliveryGroupState } from '../reducers/deliverygroup/deliveryGroupConstants';
import { DeliveryOptionState } from '../reducers/deliveryoption/deliveryOptionConstants';
import { DeliveryTierState } from '../reducers/deliverytier/deliveryTierConstants';
import { DeliveryOptionRequest } from '../types/deliveryOption';
import { DeliveryTierRequest } from '../types/deliveryTier';
import { createDeliveryConstraints } from './deliveryConstraints';
import fetchWithAuth from './fetchWithAuth';

type Operation = {
  path: string;
  action: string;
  body?: any;
};
const endpointUrl = process.env.REACT_APP_ECOMMERCE_DELIVERY_OPTIONS_URL as string;

const convertToNumber = (value?: string | number) => (value || value === 0 ? toNumber(value) : undefined);

export const batchUpdate = async ({
  deliveryGroupState,
  etag,
  deliveryOptionStates,
  deliveryTierStates,
}: {
  deliveryGroupState: DeliveryGroupState;
  etag: string;
  deliveryOptionStates: DeliveryOptionState[];
  deliveryTierStates: DeliveryTierState[];
}) => {
  const operations: Operation[] = [];

  if (deliveryGroupState.hasUnsavedWork) {
    const consolidationsWithoutFalsySkus = deliveryGroupState.productConsolidations.map(consolidation => ({
      ...consolidation,
      skus: compact(consolidation.skus),
    }));

    const trimmedMappings = deliveryGroupState.mappings.map(mapping => ({
      name: mapping.name && mapping.name.trim(),
      attributeModelUrl: mapping.attributeModelUrl,
      skus: mapping.skus,
      ecommerceDeliveryOptions: mapping.edoIds.map(id => ({ id })),
    }));

    operations.push({
      path: '/',
      action: 'replace',
      body: {
        name: deliveryGroupState?.name?.trim(),
        mappings: trimmedMappings,
        defaultEcommerceDeliveryOptions: {
          ecommerceDeliveryOptions: deliveryGroupState.defaultEdoIds.map(id => ({ id })),
        },
        timeZone: deliveryGroupState.timeZone?.value,
        consolidateCart: deliveryGroupState.consolidateCart,
        productConsolidations: consolidationsWithoutFalsySkus,
        _links: {
          calendar: {
            href: deliveryGroupState.calendarUrl,
          },
        },
      },
    });
  }

  const applicableOptions = deliveryOptionStates.filter(
    optionState => optionState.isDeleted || optionState.isNewOption || optionState.hasUnsavedWork,
  );
  const optionOperations: Operation[] = await P.map(applicableOptions, async deliveryOptionState => {
    if (deliveryOptionState.isDeleted) {
      return {
        path: `/ecommerceDeliveryOptions/${deliveryOptionState.id}`,
        action: 'remove',
      };
    }

    if (deliveryOptionState.isNewOption) {
      const createOptionRequest = await createDeliveryOptionRequest(deliveryOptionState);
      return {
        path: '/ecommerceDeliveryOptions',
        action: 'add',
        body: { ...createOptionRequest, id: deliveryOptionState.id },
      };
    }

    const updateOptionRequest = await createDeliveryOptionRequest(deliveryOptionState);
    return {
      path: `/ecommerceDeliveryOptions/${deliveryOptionState.id}`,
      action: 'replace',
      body: updateOptionRequest,
    };
  });

  const applicableTiers = deliveryTierStates.filter(
    tierState => tierState.isDeleted || tierState.isNewTier || tierState.hasUnsavedWork,
  );
  const tierOperations: Operation[] = applicableTiers.map(tierState => {
    if (tierState.isDeleted) {
      return {
        path: `/tiers/${tierState.id}`,
        action: 'remove',
      };
    }

    if (tierState.isNewTier) {
      return {
        path: '/tiers',
        action: 'add',
        body: { ...createDeliveryTierRequest(tierState), id: tierState.id },
      };
    }

    return {
      path: `/tiers/${tierState.id}`,
      action: 'replace',
      body: createDeliveryTierRequest(tierState),
    };
  });

  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'PUT',
    route: `/v1/ecommerceDeliveryGroups/${deliveryGroupState.id}:batch`,
    body: {
      operations: concat(operations, optionOperations, tierOperations),
    },
    additionalHeaders: { 'If-Match': etag },
    giveSimpleResponse: false,
  });

  const updatedEtag: string = result.response.headers.get('etag');
  return { ...result.body, etag: updatedEtag };
};

const createDeliveryTierRequest = (tierState: DeliveryTierState) => {
  const request: DeliveryTierRequest = {
    name: tierState.name?.trim() ?? '',
    dateSelection: tierState.dateSelection,
    displayNames: tierState.displayNames.map(({ locale, name, description, merchandizingText }) => ({
      locale,
      name,
      description,
      merchandizingText,
    })),
  };

  if (!isEmpty(tierState.tags)) {
    request.tags = tierState.tags;
  }

  return pickBy(request, field => !isNil(field)) as DeliveryTierRequest;
};

// the min/max days values can either be string, undefined, or number
// If we're reloading existing data, they will be numbers unless someone has edited them
// Therefore, we also need to check for numeric zero and include it in the request
const createDeliveryOptionRequest = async (
  deliveryOptionState: DeliveryOptionState,
): Promise<DeliveryOptionRequest> => {
  const request: DeliveryOptionRequest = {
    name: deliveryOptionState.name?.trim() ?? '',
    ecommerceDeliveryTierId: deliveryOptionState.deliveryTierId,
    minDays: convertToNumber(deliveryOptionState.minDays),
    maxDays: convertToNumber(deliveryOptionState.maxDays),
    minOffset: convertToNumber(deliveryOptionState.minOffset),
    maxOffset: convertToNumber(deliveryOptionState.maxOffset),
    destinations: deliveryOptionState.destinations.map(({ country, postalCodeRanges }) => ({
      country,
      postalCodeRanges,
    })),
    bufferTime: convertToNumber(deliveryOptionState.bufferTime),
    localCutoffTimes: [`${deliveryOptionState.localCutoffTimeHour}:${deliveryOptionState.localCutoffTimeMinute}:00`],
    dateSelection: deliveryOptionState.dateSelection,
    displayNames: deliveryOptionState.displayNames.map(({ locale, name, description, merchandizingText }) => ({
      locale,
      name,
      description,
      merchandizingText,
    })),
    minFallbackDays: convertToNumber(deliveryOptionState.minFallbackDays),
    maxFallbackDays: convertToNumber(deliveryOptionState.maxFallbackDays),
    ignoreInTransitInventory: deliveryOptionState.ignoreInTransitInventory,
  };

  if (!isEmpty(deliveryOptionState.tags)) {
    request.tags = deliveryOptionState.tags;
  }

  if (!isEmpty(deliveryOptionState.fulfillmentCapabilities)) {
    request.fulfillmentCapabilities = deliveryOptionState.fulfillmentCapabilities;
  }

  const { dispatchCountry, carrierServices, carrierServiceCapabilities, requireCarriers } = deliveryOptionState;

  if (dispatchCountry || !isEmpty(carrierServices) || !isEmpty(carrierServiceCapabilities)) {
    const deliveryConstraints = await createDeliveryConstraints({
      dispatchCountry,
      carrierServices: requireCarriers ? carrierServices : [],
      preferredCarrierServices: requireCarriers ? [] : carrierServices,
      carrierServiceCapabilities,
    });
    request._links = {
      deliveryConstraints: {
        href: deliveryConstraints._links.self.href,
      },
    };
  }

  return pickBy(request, field => !isNil(field)) as Promise<DeliveryOptionRequest>;
};
