import { isEmpty, partition } from 'lodash';
import { DateTime } from 'luxon';

import { Filters } from '../components/view/AuditLogPage';
import { Version } from '../reducers/deliverygroup/deliveryGroupVersionsActions';
import { getDateFromVersion, getUserFromVersion } from '../reducers/shared';

export const getVersionFilterConfig = (filters: Filters, version: Version) => {
  return {
    filters: {
      users: { filterValues: filters.users, comparator: getUserFromVersion(version) },
      dates: {
        filterValues: filters.dates,
        comparator: DateTime.fromISO(getDateFromVersion(version)).toLocaleString(DateTime.DATE_FULL),
      },
      versionTypes: { filterValues: filters.versionTypes, comparator: version.versionType },
    },
  };
};

export const checkVersionFilterConditions = ({
  filters,
}: {
  filters: { [key: string]: { filterValues: string[] | undefined; comparator: string } };
}) => {
  const entries = Object.entries(filters);
  const [activeFilters] = partition(entries, entry => entry[1] && entry[1].filterValues?.length);

  return activeFilters.every(filter => {
    const { filterValues, comparator } = filter[1];
    if (!filterValues) {
      return false;
    }

    if (filterValues.includes(comparator)) {
      return true;
    }

    return false;
  });
};

export const versionFilterGate = (filters: Filters, version: Version) => {
  if (isEmpty(filters)) {
    return true;
  }

  if (!isEmpty(filters) && checkVersionFilterConditions(getVersionFilterConfig(filters, version))) {
    return true;
  }

  return false;
};
