import { DeliveryGroupState } from './deliveryGroupConstants';

export const SET_FIELD = 'SET_FIELD';
export const ADD_MAPPING = 'ADD_MAPPING';
export const REMOVE_MAPPING = 'REMOVE_MAPPING';
export const SET_MAPPING_FIELD = 'SET_MAPPING_FIELD';
export const MOVE_MAPPING = 'MOVE_MAPPING';

export const ADD_CONSOLIDATION = 'ADD_CONSOLIDATION';
export const REMOVE_CONSOLIDATION = 'REMOVE_CONSOLIDATION';
export const SET_CONSOLIDATION_FIELD = 'SET_CONSOLIDATION_FIELD';
export const MOVE_CONSOLIDATION = 'MOVE_CONSOLIDATION';

export const POPULATE_DELIVERY_GROUP = 'POPULATE_DELIVERY_GROUP';

export type FieldPayload = {
  field: string;
  value: unknown;
};

export type MovePayload = {
  sourceIndex: number;
  destinationIndex: number;
};

export const setField = (payload: FieldPayload) => ({ type: SET_FIELD, payload });
export const addMapping = () => ({ type: ADD_MAPPING });
export const removeMapping = (payload: { mappingId: string }) => ({ type: REMOVE_MAPPING, payload });
export const setMappingField = (payload: { mappingId: string; field: string; value: unknown }) => ({
  type: SET_MAPPING_FIELD,
  payload,
});
export const moveMapping = (payload: MovePayload) => ({
  type: MOVE_MAPPING,
  payload,
});

export const addConsolidation = () => ({ type: ADD_CONSOLIDATION });
export const removeConsolidation = (payload: { consolidationId: string }) => ({ type: REMOVE_CONSOLIDATION, payload });
export const setConsolidationField = (payload: { consolidationId: string; field: string; value: unknown }) => ({
  type: SET_CONSOLIDATION_FIELD,
  payload,
});
export const moveConsolidation = (payload: MovePayload) => ({
  type: MOVE_CONSOLIDATION,
  payload,
});

export const populateDeliveryGroup = (payload: DeliveryGroupState) => ({ type: POPULATE_DELIVERY_GROUP, payload });
