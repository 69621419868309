import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import {
  DeliveryOptionState,
  DestinationState,
  PostalCodeRange,
} from '../reducers/deliveryoption/deliveryOptionConstants';
import { HydratedDeliveryOptionResponse } from '../types/deliveryOption';

const hydrateDeliveryOption = (data: HydratedDeliveryOptionResponse): DeliveryOptionState => {
  const { deliveryOption, deliveryConstraints } = data;

  const destinations: DestinationState[] = deliveryOption.destinations?.map(destination => {
    const newPostalCodeRanges: PostalCodeRange[] = destination.postalCodeRanges.map(postalCodeRange => ({
      ...postalCodeRange,
      id: uuid(),
    }));
    return { ...destination, id: uuid(), postalCodeRanges: newPostalCodeRanges };
  });

  const requireCarriers = !isEmpty(deliveryConstraints?.carrierServices);
  const localCutoffTime = deliveryOption.localCutoffTimes[0].split(':');

  return {
    id: deliveryOption.id,
    name: deliveryOption.name,
    destinations: destinations ?? [],
    bufferTime: deliveryOption.bufferTime,
    minDays: deliveryOption.minDays,
    maxDays: deliveryOption.maxDays,
    minOffset: deliveryOption.minOffset,
    maxOffset: deliveryOption.maxOffset,
    localCutoffTimeHour: localCutoffTime[0],
    localCutoffTimeMinute: localCutoffTime[1],
    dateSelection: deliveryOption.dateSelection,
    minFallbackDays: deliveryOption.minFallbackDays,
    maxFallbackDays: deliveryOption.maxFallbackDays,
    displayNames: deliveryOption.displayNames?.map(displayName => ({ ...displayName, id: uuid() })) ?? [],
    dispatchCountry: deliveryConstraints?.dispatchCountry,
    carrierServices:
      (requireCarriers ? deliveryConstraints?.carrierServices : deliveryConstraints?.preferredCarrierServices) ?? [],
    requireCarriers,
    carrierServiceCapabilities: deliveryConstraints?.carrierServiceCapabilities ?? [],
    fulfillmentCapabilities: deliveryOption.fulfillmentCapabilities ?? [],
    ignoreInTransitInventory: deliveryOption.ignoreInTransitInventory ?? false,
    tags: deliveryOption.tags ?? [],
    deliveryTierId: deliveryOption.ecommerceDeliveryTierId,
    hasUnsavedWork: false,
    isNewOption: false,
    isDeleted: false,
    isValid: true,
  };
};

export default hydrateDeliveryOption;
