import { useQuery } from '@tanstack/react-query';

import { getDeliveryGroup } from '../../services/deliveryGroups';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';

const useDeliveryGroup = ({
  id,
  onError,
  onSuccess,
  enabled = true,
}: {
  id: string;
  onError?: (error: Error) => void;
  onSuccess?: (data: DeliveryGroupResponse & { etag: string; accountName: string }) => void;
  enabled?: boolean;
}) => {
  return useQuery(['delivery-group', { id }], () => getDeliveryGroup({ id }), {
    enabled,
    onError,
    onSuccess,
  });
};

export default useDeliveryGroup;
