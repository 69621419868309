import auth from '../auth';

type ServiceResponse = {
  status: number;
  statusText: string;
  ok: boolean;
  body: string | Record<string, unknown>;
};

export class ExtensibleError extends Error {
  additionalInfo: Record<string, unknown> | undefined;

  constructor(message: string | undefined, additionalInfo?: Record<string, unknown>) {
    super(message);
    this.name = this.constructor.name;
    this.additionalInfo = additionalInfo;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class ServiceHttpResponseError extends ExtensibleError {
  response: ServiceResponse;
  responseMessage: string | undefined;

  constructor(message: string | undefined, response: ServiceResponse, additionalInfo?: Record<string, unknown>) {
    super(message, additionalInfo);

    this.response = response;
    if (response && response.body) {
      if (typeof response.body === 'object') {
        this.responseMessage = response.body.message as string;
      } else {
        this.responseMessage = response.body;
      }
    }
  }

  toString() {
    return `${this.message} - ${this.response.status} (${this.response.statusText})\n\t${this.responseMessage || ''}`;
  }
}

export const buildOptions = (
  method = 'GET',
  body: string | Record<string, unknown> | undefined,
  additionalHeaders: Record<string, string> | undefined,
): RequestInit => {
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${auth.getAccessToken()}`,
    ...additionalHeaders,
  });

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  if (body && typeof body !== 'string') {
    // eslint-disable-next-line no-param-reassign
    body = JSON.stringify(body);
  }

  return {
    method,
    mode: 'cors',
    headers,
    body,
  };
};

export const checkResponse = async (res: Response, serviceComment: string, isSimpleResponse: boolean) => {
  let body;
  if (res.ok) {
    if (res.status === 204) {
      return isSimpleResponse ? {} : { response: res };
    }
    body = await res.json();
    return isSimpleResponse ? body : { response: res, body };
  }

  const response: ServiceResponse = {
    status: res.status,
    statusText: res.statusText,
    ok: res.ok,
    body: await (/application\/json/.test(res.headers.get('content-type') as string) ? res.json() : res.text()),
  };
  throw new ServiceHttpResponseError(`Error fetching ${serviceComment}`, response);
};
