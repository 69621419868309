import { useMemo, useState } from 'react';

import { Filters } from './HomePage';
import TableFilter, { accordionFilter } from './TableFilter';

export const HomeFilters = ({
  filteredAccountIds,
  filteredCreators,
  setFilters,
  accountOptions,
  creatorOptions,
}: {
  filteredAccountIds: string[];
  filteredCreators: string[];
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  accountOptions: { id: string; name: string }[];
  creatorOptions: string[];
}) => {
  const [creators, setCreators] = useState<string[]>([]);
  const [accountIds, setAccountIds] = useState<string[]>([]);

  const accountAccordion = useMemo(
    () =>
      accordionFilter(
        'Accounts',
        filteredAccountIds,
        accountIds,
        accountOptions.map(({ id, name }) => ({ payload: id, label: `${name} (${id})` })),
        setAccountIds,
      ),
    [filteredAccountIds, accountIds, accountOptions],
  );

  const createdByAccordion = useMemo(
    () =>
      accordionFilter(
        'Creators',
        filteredCreators,
        creators,
        creatorOptions.map(creator => ({ payload: creator, label: creator })),
        setCreators,
      ),
    [filteredCreators, creators, creatorOptions],
  );

  const filterProps = useMemo(
    () => [
      {
        filterName: 'accountIds',
        filteredValues: filteredAccountIds,
        setFilteredValues: setAccountIds,
        filterOptions: accountIds,
      },
      {
        filterName: 'creators',
        filteredValues: filteredCreators,
        setFilteredValues: setCreators,
        filterOptions: creators,
      },
    ],
    [filteredAccountIds, accountIds, filteredCreators, creators],
  );

  return (
    <TableFilter filterProps={filterProps} setFilters={setFilters}>
      {accountAccordion}
      {createdByAccordion}
    </TableFilter>
  );
};
