// tier list-level actions
import { DeliveryTierState } from './deliveryTierConstants';

export const ADD_DELIVERY_TIER = 'ADD_DELIVERY_TIER';
export const REMOVE_DELIVERY_TIER = 'REMOVE_DELIVERY_TIER';
export const POPULATE_DELIVERY_TIERS = 'POPULATE_DELIVERY_TIERS';

// tier-level actions
export const VALIDATE_DELIVERY_TIER = 'VALIDATE_DELIVERY_TIER';
export const SET_FIELD = 'SET_FIELD';

type TierPayload = {
  tierId: string;
};

type FieldPayload = {
  tierId: string;
  field?: string;
  value?: unknown;
};
export const addDeliveryTier = () => ({ type: ADD_DELIVERY_TIER });
export const removeDeliveryTier = (payload: TierPayload) => ({ type: REMOVE_DELIVERY_TIER, payload });
export const populateDeliveryTiers = (payload: DeliveryTierState[]) => ({ type: POPULATE_DELIVERY_TIERS, payload });

export const validateDeliveryTier = (payload: TierPayload & { isValid: boolean }) => ({
  type: VALIDATE_DELIVERY_TIER,
  payload,
});

export const setField = (payload: FieldPayload) => ({ type: SET_FIELD, payload });
