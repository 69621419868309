import { v4 as uuid } from 'uuid';

import { SelectField } from '../../types/shared';

export type DeliveryGroupState = {
  id?: string;
  name?: string;
  mappings: MappingState[];
  defaultEdoIds: string[];
  account?: SelectField;
  timeZone?: SelectField;
  calendarUrl?: string;
  consolidateCart: boolean;
  productConsolidations: ProductConsolidationState[];
  etag?: string;
  hasUnsavedWork: boolean;
};

export type MappingState = {
  mappingId: string;
  name?: string;
  attributeModelUrl?: string;
  skus: string[];
  edoIds: string[];
};

export type ProductConsolidationState = {
  consolidationId: string;
  name?: string;
  skus: string[];
};

export const initialDeliveryGroup = (): DeliveryGroupState => {
  return {
    id: undefined,
    name: undefined,
    mappings: [],
    defaultEdoIds: [],
    account: undefined,
    timeZone: undefined,
    calendarUrl: undefined,
    consolidateCart: false,
    productConsolidations: [],
    etag: undefined,
    hasUnsavedWork: true,
  };
};

export const initialConsolidation = () => {
  return {
    consolidationId: uuid(),
    name: '',
    skus: [],
  };
};

export const initialMapping = () => {
  return {
    mappingId: uuid(),
    name: undefined,
    attributeModelUrl: undefined,
    skus: [],
    edoIds: [],
  };
};
