import { css } from '@emotion/css';
import * as JsSearch from 'js-search';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';
import { Button, FlexBox, ReactTablev6, TextField } from '@cimpress/react-components';

import { useAccounts } from '../../hooks/useAccounts';
import useDeliveryGroups from '../../hooks/view/useDeliveryGroups';
import { getAccountsWithPermission } from '../../services/coam';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';
import { CreateButton } from '../create/CreateButton';
import { CreateEdo } from '../view/CreateEdo';
import { HomeFilters } from './HomeFilters';

type NameColumn = {
  original: {
    id: string;
  };
  value: string;
};

export type Filters = {
  offset: number;
  limit: number;
  creators?: string[];
  accountIds?: string[];
};

const searchCss = css`
  margin-bottom: 20px;
`;

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ original, value }: NameColumn) => <Link to={`/groups/${original.id}`}>{value}</Link>,
  },
  {
    Header: 'Account',
    accessor: 'accountInfo',
  },
  { Header: 'Created By', accessor: 'createdBy' },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
  },
  {
    Header: 'Modified By',
    accessor: 'modifiedBy',
    Cell: ({ value }: { value: string }) => value ?? 'N/A',
  },
  {
    Header: 'Modified At',
    accessor: 'modifiedAt',
    Cell: ({ value }: { value: string }) =>
      value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'N/A',
  },
];

const HomePage = () => {
  const [hasPermission, setHasPermission] = useState(true);
  const [filters, setFilters] = useState<Filters>({ offset: 0, limit: 200 });
  const [accountOptions, setAccountOptions] = useState<{ id: string; name: string }[]>([]);
  const [creatorOptions, setCreatorOptions] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { isLoading: isLoadingAccounts, getAccountNameById, data: accounts } = useAccounts({ filter: false });
  const { isLoading: isLoadingDeliveryGroups, data: deliveryGroups } = useDeliveryGroups(filters);

  const parsedDeliveryGroups = map(deliveryGroups, group => ({
    id: group.id,
    name: group.name,
    accountInfo: `${getAccountNameById(group.account.id)} (${group.account.id})`,
    createdAt: group.createdAt,
    createdBy: group.createdBy,
    modifiedAt: group.modifiedAt,
    modifiedBy: group.modifiedBy,
  }));

  const { creators, accountIds } = filters;

  // Get the filter options before filters have been set
  // This is to prevent the filter options from changing as we filter down
  useEffect(() => {
    const getAccountInfo = (deliveryGroups: DeliveryGroupResponse[]) => {
      const deliveryGroupAccounts = uniq(map(deliveryGroups, 'account.id'));
      return map(deliveryGroupAccounts, accountId => ({
        id: accountId,
        name: getAccountNameById(accountId),
      }));
    };
    const getCreators = (deliveryGroups: DeliveryGroupResponse[]) => uniq(map(deliveryGroups, 'createdBy'));

    if (deliveryGroups && isEmpty(creators) && isEmpty(accountIds)) {
      setAccountOptions(getAccountInfo(deliveryGroups));
      setCreatorOptions(getCreators(deliveryGroups));
    }
  }, [creators, accountIds, deliveryGroups, getAccountNameById]);

  useEffect(() => {
    if (isLoadingAccounts) {
      return;
    }

    const getPermissionedAccounts = async () => {
      if (!accounts) {
        setHasPermission(false);
        return;
      }

      const permissionedAccounts = await getAccountsWithPermission(accounts);

      if (permissionedAccounts.length === 0) {
        setHasPermission(false);
      }
    };

    getPermissionedAccounts();
  }, [accounts, isLoadingAccounts]);

  const search = new JsSearch.Search('id');
  search.addIndex('name');
  search.addIndex('accountInfo');
  search.addIndex('createdBy');
  search.addDocuments(parsedDeliveryGroups);
  const tableData = searchQuery.trim() !== '' ? search.search(searchQuery) : parsedDeliveryGroups;

  return (
    <div className="container-fluid">
      <FlexBox>
        <div style={{ margin: '0 0 10px auto' }}>
          <CreateButton hasPermission={hasPermission} />
        </div>
      </FlexBox>
      <div className="content-wrapper">
        <FlexBox center className={searchCss}>
          <TextField
            style={{ flexGrow: 1, margin: '0 10px 0 0' }}
            className="search"
            value={searchQuery}
            label="Search by name, account, or creator"
            onChange={e => setSearchQuery(e.target.value)}
            rightAddon={
              <Button variant="primary">
                <IconSearch style={{ paddingRight: 5 }} />
                Search
              </Button>
            }
          />
          <HomeFilters
            filteredAccountIds={filters.accountIds ?? []}
            filteredCreators={filters.creators ?? []}
            setFilters={setFilters}
            accountOptions={accountOptions}
            creatorOptions={creatorOptions}
          />
        </FlexBox>
        <ReactTablev6
          columns={columns}
          data={tableData}
          loading={isLoadingDeliveryGroups || isLoadingAccounts}
          loadingText="Loading Ecommerce Delivery Options"
          NoDataComponent={props => CreateEdo(props, hasPermission)}
          defaultSorted={[
            {
              id: 'createdAt',
              desc: true,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default HomePage;
