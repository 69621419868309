import some from 'lodash/some';

import { AccountInfo } from './accounts';
import { fetchWithAuth } from './fetchWithAuth';

type Permissions = {
  resource_type: string;
  identifier: string;
  permissions: string[];
}[];

const coamEndpointUrl = process.env.REACT_APP_COAM_URL as string;

export const getAccountsWithPermission = async (accountsToFilter: AccountInfo[]): Promise<AccountInfo[]> => {
  const permissions: Permissions = await fetchWithAuth({
    endpointUrl: coamEndpointUrl,
    route:
      'auth/access-management/v1/principals/self/permissions/account?include=true&permissionFilter=create:ecommerce-delivery-group',
  });

  // If the user has the star permission, don't filter
  if (some(permissions, { identifier: '*' })) {
    return accountsToFilter;
  } else {
    const accountIds = permissions.map(account => account.identifier);
    return accountsToFilter.filter(account => accountIds.includes(account.id));
  }
};
