import React from 'react';

import { Button, Modal, colors } from '@cimpress/react-components';

const DeletePrompt = ({
  text,
  show,
  onCancel,
  onDelete,
}: {
  text: string;
  show: boolean;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  const onCancelDelete = () => {
    onCancel && onCancel();
  };

  const onConfirmDelete = () => {
    onDelete && onDelete();
  };

  const footer = (
    <div>
      <Button variant="outline-secondary" onClick={onCancelDelete}>
        Cancel
      </Button>
      <Button
        variant="primary"
        style={{ background: colors.danger.base, border: colors.danger.base }}
        onClick={onConfirmDelete}
      >
        Delete
      </Button>
    </div>
  );

  return (
    <Modal
      closeButton
      closeOnOutsideClick
      title="Are you sure?"
      footer={footer}
      show={show}
      onRequestHide={onCancelDelete}
    >
      {text}
    </Modal>
  );
};

export default DeletePrompt;
