import P from 'bluebird';
import orderBy from 'lodash/orderBy';

import { Filters } from '../components/view/AuditLogPage';
import {
  DeliveryOptionResponse,
  DeliveryOptionVersionResponse,
  DeliveryOptionsResponse,
  HydratedDeliveryOptionResponse,
} from '../types/deliveryOption';
import { fetchWithAuth } from './fetchWithAuth';
import { versionFilterGate } from './shared';

const endpointUrl = process.env.REACT_APP_ECOMMERCE_DELIVERY_OPTIONS_URL as string;

export const getDeliveryOptions = async ({
  deliveryGroupId,
}: {
  deliveryGroupId: string;
}): Promise<HydratedDeliveryOptionResponse[]> => {
  const deliveryOptionResponse: HydratedDeliveryOptionResponse[] = [];
  let next:
    | string
    | undefined = `${endpointUrl}/v1/ecommerceDeliveryGroups/${deliveryGroupId}/ecommerceDeliveryOptions`;

  do {
    const response: DeliveryOptionsResponse = await fetchWithAuth({
      endpointUrl: next,
    });

    const hydratedOptions: HydratedDeliveryOptionResponse[] = await P.map(
      response._embedded.ecommerceDeliveryOptions,
      async (deliveryOption: DeliveryOptionResponse) => {
        const hydratedOption: HydratedDeliveryOptionResponse = { deliveryOption };

        const deliveryConstraintsLink = deliveryOption._links.deliveryConstraints?.href;
        if (deliveryConstraintsLink) {
          hydratedOption.deliveryConstraints = await fetchWithAuth({ endpointUrl: deliveryConstraintsLink });
        }

        return hydratedOption;
      },
    );

    deliveryOptionResponse.push(...hydratedOptions);
    next = response._links.next?.href;
  } while (next);

  return orderBy(deliveryOptionResponse, ['deliveryOption.name'], ['asc']);
};

export const getDeliveryOptionVersions = async ({
  deliveryGroupId,
  deliveryOptionId,
  filters,
}: {
  deliveryGroupId: string;
  deliveryOptionId: string;
  filters: Filters;
}): Promise<DeliveryOptionVersionResponse & { etag: string }> => {
  // Currently only requests most recent 50 versions
  const result: any = await fetchWithAuth({
    endpointUrl,
    method: 'GET',
    route: `/v1/ecommerceDeliveryGroups/${deliveryGroupId}/ecommerceDeliveryOptions/${deliveryOptionId}/versions?offset=0&limit=50&sort=createdAt%3Adesc`,
    giveSimpleResponse: false,
  });

  const deliveryOptionVersionsResponse = result.body as DeliveryOptionVersionResponse;
  deliveryOptionVersionsResponse._embedded.item = deliveryOptionVersionsResponse._embedded.item.filter(item => {
    item.versionType = 'option';
    return versionFilterGate(filters, item);
  });

  const etag = result.response.headers.get('etag');

  return { ...deliveryOptionVersionsResponse, etag };
};
