import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cloneDeliveryGroup } from '../../services/deliveryGroups';
import { DeliveryGroupResponse } from '../../types/deliveryGroup';

const useCloneDeliveryGroup = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: DeliveryGroupResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(cloneDeliveryGroup, {
    onSuccess: data => {
      queryClient.setQueryData(['delivery-group', { id: data.id }], data);
      onSuccess && onSuccess(data);
    },
    onError,
  });
};

export default useCloneDeliveryGroup;
