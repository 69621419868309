import produce from 'immer';

import { DeliveryVersionActionTypes } from './deliveryGroupVersionsActions';

const deliveryGroupVersionsReducer = produce((draft, action) => {
  switch (action.type) {
    case DeliveryVersionActionTypes.ADD_VERSIONS: {
      const { response } = action.payload;
      const { _embedded } = response;
      if (!_embedded) {
        return;
      }

      const { item: versions } = _embedded;

      return { versions };
    }

    default: {
      return draft;
    }
  }
});

export default deliveryGroupVersionsReducer;
